import { Card, Text } from "@nextui-org/react";
import CardMedia from '@mui/material/CardMedia';

export default function App({foto,text,url}) {

  
  return (
    <a href={url} target="_blank"> 
    <Card 
      isPressable
      isHoverable
      variant="bordered"
    >
      <Card.Body>
      
      <CardMedia 
       title={text}
        component="img"
        image={foto}
        style={{width:"300px"}}
        alt="Paella dish"
      />
      </Card.Body>
    </Card>
    </a>
  );
}
