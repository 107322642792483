import * as React from 'react';
import { useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import seguridad from '../recursos/seguridad.png'
import { TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { encrypt } from '../Componentes/FunctionLogin';

export default function Encrypt() {
  const [nit, setNit] = useState('');
  const [nitEncriptado, setNitEncriptado] = useState('');
  const [providerType, setProviderType] = useState('nacional'); // Default provider type

  const handleNitChange = (event) => {
    setNit(event.target.value);
  };

  const handleProviderTypeChange = (event) => {
    setProviderType(event.target.value);
  };

  const handleEncryptClick = () => {
    const linkPrefix =
      providerType === 'nacional'
        ? 'proveedores.citalsa.com/proveedores/registro?id='
        : 'proveedores.citalsa.com/proveedores/registrointernacional?id=';

    encrypt(nit).then((result) => {
      setNitEncriptado(linkPrefix + result);
    });
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="sm">
        <Card sx={{ maxWidth: 600 }}>
          <CardMedia sx={{ height: 350, width: 500 }} image={seguridad} title="Seguridad" />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Encriptado
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Generador de links proveedores
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', maxWidth: '650px' }}>
              <TextField label="NIT" variant="outlined" value={nit} onChange={handleNitChange} />
              <TextField
                label="NIT Encriptado"
                variant="outlined"
                value={nitEncriptado}
                disabled
              />
              <FormControl variant="outlined">
                <InputLabel htmlFor="provider-type">Tipo de Proveedor</InputLabel>
                <Select
                  value={providerType}
                  onChange={handleProviderTypeChange}
                  label="Tipo de Proveedor"
                  inputProps={{ id: 'provider-type' }}
                >
                  <MenuItem value="nacional">Nacional</MenuItem>
                  <MenuItem value="internacional">Internacional</MenuItem>
                </Select>
              </FormControl>
              <Button variant="contained" onClick={handleEncryptClick}>
                Encriptar
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </React.Fragment>
  );
}
