import * as React from 'react';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Textarea from '@mui/joy/Textarea';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import Stack from '@mui/joy/Stack';
import Add from '@mui/icons-material/Add';
import Typography from '@mui/joy/Typography';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

import { useTranslation } from 'react-i18next'
import { CambiosCantidadFechas } from '../Componentes/FunctionLogin.js'

export default function BasicModalDialog({ oc, PosicionPedido, DescProducto, ProductoId, PrecioNeto, CantidadPedida, Proveedor, text, boton, tipo }) {
  let date = new Date();
  const [open, setOpen] = React.useState(false);
  const [fechainicial, setfechainicial] = React.useState(dayjs(date.toISOString().split('T')[0]));

  const [t, i18n] = useTranslation("global");

  const [cantidad, setCantidad] = React.useState(0);
  const [precio, setPrecio] = React.useState(0);
  const [descripcion, setDescripcion] = React.useState("");


  return (
    <React.Fragment>
      <Button
        variant="outlined"
        color="neutral"
        startDecorator={<Add />}
        onClick={() => setOpen(true)}
      >
        {boton}
      </Button>

      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog
          aria-labelledby="basic-modal-dialog-title"
          aria-describedby="basic-modal-dialog-description"
          sx={{ maxWidth: 500 }}
        >
          <Typography id="basic-modal-dialog-title" component="h2">
            {oc} - {t("cambios.linea")} : {PosicionPedido}
          </Typography>
          <Typography id="basic-modal-dialog-description" textColor="text.tertiary">
            {text} -{DescProducto} Sku: {ProductoId}
          </Typography>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              CambiosCantidadFechas(Proveedor, ProductoId, DescProducto, PosicionPedido, cantidad, dayjs(fechainicial).format('YYYY-MM-DD'), descripcion,precio,oc).then((result) => {
       
              });
              setOpen(false);
            }}
          >
            <Stack spacing={2}>
              <FormControl>
                <FormLabel>{t("cambios.proveedor")}</FormLabel>
                <Input value={Proveedor} autoFocus required disabled />
              </FormControl>

              <FormControl>
                <FormLabel>{t("cambios.precio")}</FormLabel>
                <Input type="number" minRows={2} value={precio} onChange={(e) => {
                  setPrecio(e.target.value)
                }} required />
              </FormControl>
              <FormControl>
                <FormLabel>{t("cambios.cantidad")}</FormLabel>
                <Input type="number" minRows={2} value={cantidad} onChange={(e) => {
                  setCantidad(e.target.value)
                }} required />
              </FormControl>
              <FormControl>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={['DatePicker', 'DatePicker']}>
                    <DatePicker label={t("cambios.nuevaFecha")} value={fechainicial}
                      onChange={(newValue) => { setfechainicial(newValue); }} />
                  </DemoContainer>
                </LocalizationProvider>
              </FormControl>



              <FormControl>
                <FormLabel>{t("cambios.explicacion")} </FormLabel>
                <Textarea minRows={2} required value={descripcion} onChange={(e) => {
                  setDescripcion(e.target.value)
                }} />
              </FormControl>
              <Button type="submit">{t("cambios.enviar")}</Button>
            </Stack>
          </form>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}