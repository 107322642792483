import React, { useState, useRef } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TextField,
    TableRow,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    Paper,
    Typography,
    Container,
    Alert
} from '@mui/material';
import SignatureCanvas from "react-signature-canvas";
import swal from 'sweetalert';
import styled from 'styled-components';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import mammoth from 'mammoth';
import PizZip from 'pizzip';
import Docxtemplater from 'docxtemplater';
import { saveAs } from 'file-saver';

import { generatePDF } from './calidadPDF'

import { EvaluacionProveedor, ingresoPDFWorkmanagerFlujo, ingresoDOCXWorkmanagerFlujo } from '../FunctionLogin'
import { pdfCalidadBase64 } from '../../variables/variablesGlobales'

const EvaluationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px auto;
  max-width: 1200px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
`;

const EvaluationTitle = styled(Typography)`
  margin-bottom: 20px;
`;

const TableContainerStyled = styled(TableContainer)`
  margin-top: 20px;
  margin-bottom: 40px;
`;

const TableCellHead = styled(TableCell)`
  font-weight: bold;
  background-color: #f5f5f5;
`;

const TableCellBody = styled(TableCell)`
  font-size: 14px;
  padding: 12px;
`;

const SelectFormControl = styled(FormControl)`
  min-width: 80px;
`;

export default function EvaluationCalidad() {


    const [loading, setLoading] = useState(false);
    const [codigoInternoProveedor, setCodigoInternoProveedor] = useState(localStorage.Proveedor);
    const [nombreProveedor, setNombreProveedor] = useState(localStorage.Tproveedor);
    const [tipoDocumento, setTipoDocumento] = useState('');
    const [numeroDocumento, setNumeroDocumento] = useState('');
    const [direccion, setDireccion] = useState('');
    const [prestadorBienesServicios, setPrestadorBienesServicios] = useState('');
    const [evaluador, setEvaluador] = useState('');
    const [cargo, setCargo] = useState('');
    const [categoria, setCategoria] = useState('');
    const [fechaEvaluacion, setFechaEvaluacion] = useState('');
    const [periodoEvaluado, setPeriodoEvaluado] = useState('');
    const [correoProveedor, setCorreoProveedor] = useState('');
    const sigPad = useRef({});
    const sigPad2 = useRef({});
    const [firmaFile, setFirmaFile] = useState(null);
    const [firmaFile2, setFirmaFile2] = useState(null);
    const [observacion, setObservacion] = useState('');

    const [responsableVisita, setResponsableVisita] = useState('');
    const [responsableRecibir, setResponsableRecibir] = useState('');

    const handleCodigoInternoProveedorChange = (event) => {
        setCodigoInternoProveedor(event.target.value);
    };

    const handleNombreProveedorChange = (event) => {
        setNombreProveedor(event.target.value);
    };

    const handleTipoDocumentoChange = (event) => {
        setTipoDocumento(event.target.value);
    };

    const handleNumeroDocumentoChange = (event) => {
        setNumeroDocumento(event.target.value);
    };

    const handleDireccionChange = (event) => {
        setDireccion(event.target.value);
    };

    const handlePrestadorBienesServiciosChange = (event) => {
        setPrestadorBienesServicios(event.target.value);
    };

    const handleEvaluadorChange = (event) => {
        setEvaluador(event.target.value);
    };

    const handleCargoChange = (event) => {
        setCargo(event.target.value);
    };

    const handleCategoriaChange = (event) => {
        setCategoria(event.target.value);
    };

    const handleFechaEvaluacionChange = (event) => {
        setFechaEvaluacion(event.target.value);
    };

    const handlePeriodoEvaluadoChange = (event) => {
        setPeriodoEvaluado(event.target.value);
    };

    const handleCorreoProveedorChange = (event) => {
        setCorreoProveedor(event.target.value);
    };

    const handleObservacionChange = (event) => {
        setObservacion(event.target.value);
    };



    const evaluationData = [
        {
            "category": "CALIDAD DEL BIEN Y/O SERVICIO",
            "questions": [
                {
                    "question": "¿¿Cumple con el objeto de la orden de compra?",
                    "index": 0
                },
                {
                    "question": "¿La calidad de las especificaciones del bien, producto o servicio cumple con lo requerido?",
                    "index": 1
                },
                {
                    "question": "¿Cumplimiento al 100% en la entrega de las cantidades requeridas? 1: No cumple 3: Cumple",
                    "index": 2
                },
                {
                    "question": "¿Tiempo de respuesta a las garantías y reclamos? Demorado:1 Intermedio: 2  Ágil:3",
                    "index": 3
                },
                {
                    "question": "¿Índice de reclamos y rechazos? Alto:1  Medio:2 Bajo: 3",
                    "index": 4
                }
            ]
        },
        {
            "category": "COMERCIALES",
            "questions": [
                {
                    "question": "¿Forma de pago? Contado = 1 16-30 días = 2 31 días en adelante = 3",
                    "index": 5
                },
                {
                    "question": "¿Competitividad en el costo total de adquisición (precios, valor agregado, entregas, fletes, recombras, Costos adicionales por envíos, etc.)?",
                    "index": 6
                },
                {
                    "question": "¿Flexibilidad del proveedor? (Pedidos mínimos/ Manejo de urgencias)",
                    "index": 7
                },
                {
                    "question": "Innovación (Propuestas de mejora en la competitividad, actualizaciones tecnológicas, conectividad, seguimiento proactivo a las entregas)",
                    "index": 8
                }
            ]
        },
        {
            "category": "OPORTUNIDAD EN LA RESPUESTA A LOS REQUERIMIENTOS",
            "questions": [
                {
                    "question": "¿El proveedor brinda respuestas oportunas a las cotizaciones, proformas, inquietudes, asesorías y/o consultas en general? SI:3  PARCIAL: 2 No: 1",
                    "index": 9
                }
            ]
        },
        {
            "category": "CUMPLIMIENTO TOTAL DE PEDIDOS ENTREGADOS A TIEMPO",
            "questions": [
                {
                    "question": "¿Cuál es el nivel de cumplimiento con los plazos de entrega? (Calificación: 1 si se encuentra por debajo del 50%, calificación: 2 si se encuentra entre el 51% y el 70%, calificación: 3 del 90% en adelante).",
                    "index": 10
                }
            ]
        },
        {
            "category": "AMBIENTALES",
            "questions": [
                {
                    "question": "¿Cuenta la empresa con un plan de gestión ambiental?",
                    "index": 11
                },
                {
                    "question": "¿Demuestra la empresa el uso de medidas para la reducción de impactos negativos al medio ambiente, como la medición de huella de carbono, huella hídrica, reciclaje, reúso, retomas de materiales de empaque y embalaje, y control de vertimientos? ¿Cuenta con certificaciones ambientales? (Calificación: 3 si cumple con todos los aspectos mencionados, calificación: 2 si cumple parcialmente, calificación: 1 si no cumple).",
                    "index": 12
                }
            ]
        },
        {
            "category": "PSICOSOCIALES",
            "questions": [
                {
                    "question": "¿Cuenta la empresa con planes para la gestión y control de riesgos psicosociales, riesgos laborales, seguridad y salud en el trabajo? (Calificación: 3 si cuenta con planes completos, calificación: 2 si cuenta con planes parciales, calificación: 1 si no cuenta con planes).",
                    "index": 13
                },
                {
                    "question": "¿Se incentivan las actividades de voluntariado, obras sociales y apoyo a fundaciones?",
                    "index": 17
                },
                {
                    "question": "¿Qué beneficios se otorgan a los colaboradores? (Salarios justos, incentivos educativos, economicos, sociales, entre otros)",
                    "index": 18
                }
            ]
        },
        {
            "category": "NEGOCIOS CONFIABLES",
            "questions": [
                {
                    "question": "¿Cuenta la empresa con un código de ética y conducta que aplique a empleados, proveedores, accionistas y otros? (Calificación: 3 si cuenta con un código completo, calificación: 2 si cuenta con un código parcial, calificación: 1 si no cuenta con un código).",
                    "index": 14
                }
            ]
        },
        {
            "category": "DOCUMENTACIÓN DEL PROVEEDOR",
            "questions": [
                {
                    "question": "¿Cumple la empresa con las políticas internas de compras de TALSA y suministra la documentación requerida en la selección, evaluación y seguimiento de proveedores? (Calificación: 3 si los documentos están completos, calificación: 2 si los documentos están incompletos, calificación: 1 si no se suministra la documentación)",
                    "index": 15
                }
            ]
        },
        {
            "category": "SERVICIOS POST VENTA",
            "questions": [
                {
                    "question": "¿Cumple la empresa con las promesas de nivel de servicio, como: respaldo, garantías, reposición de productos o servicios, y brinda asesorías y formaciones? (Calificación: 3 si cumple con todas las promesas, calificación: 2 si cumple parcialmente, calificación: 1 si no cumple).",
                    "index": 16
                },
                {
                    "question": "¿La empresa demuestra proactividad y realiza un seguimiento oportuno en las entregas y cobertura del servicio a nivel nacional, incluyendo las sedes de TALSA? (Calificación: 3 si es proactiva y realiza un seguimiento oportuno, calificación: 2 si es parcialmente proactiva, calificación: 1 si no cumple con la proactividad y seguimiento oportuno).",
                    "index": 17
                }
            ]
        }
    ];

    const asociados = [
        { tipo: 'FAVORABLE', puntaje: '> 80', accion: 'Resaltar los puntos fuertes para reforzar la posición actual y los aspectos destacables que demuestran el buen desempeño.', control: 'No requiere verificación' },
        {
            tipo: 'FAVORABLE CONDICIONAL', puntaje: '> 60 y <= 80', accion: 'Identificar los puntos de mejora para que se implementen acciones correctivas que evidencien la superación de dichas debilidades. Se programará una nueva evaluación en un plazo máximo de 6 meses.',
            control: 'Se realizará una verificación eventual a los tres meses para evaluar el desempeño y cumplimiento del proveedor.'
        },
        {
            tipo: 'NO FAVORABLE', puntaje: '> 0 y <= 60', accion: 'Comunicar al proveedor que no ha cumplido con los parámetros esperados para mantener la continuidad de sus servicios. Esta evaluación es considerada NO FAVORABLE.',
            control: 'Estudiar los planes de acción enviados por el proveedor'
        },
    ];

    const [evaluations, setEvaluations] = useState(Array(18).fill(0));

    const handleEvaluationChange = (index, value) => {
        setEvaluations((prevEvaluations) => {
            const newEvaluations = [...prevEvaluations];
            newEvaluations[index] = value;
            return newEvaluations;
        });
    };

    const [totalPoints, setTotalPoints] = useState(0);

    const sumPoints = () => {
        const sum = evaluations.reduce((total, evaluation) => total + evaluation, 0);
        setTotalPoints(sum);
    };

    const handleClick = () => {
        try {
            setLoading(true)

            const tableData = [];
            evaluationData.forEach((category, categoryIndex) => {
                const categoryData = {
                    category: category.category,
                    questions: [],
                };
                category.questions.forEach((question, questionIndex) => {
                    const questionData = {
                        question: question.question,
                        evaluation: evaluations[question.index] || "",
                    };
                    categoryData.questions.push(questionData);
                });
                tableData.push(categoryData);
            });
            const camposRequeridos = [
                { campo: 'nombreProveedor', valor: nombreProveedor, mensaje: 'Nombre del proveedor' },
                { campo: 'tipoDocumento', valor: tipoDocumento, mensaje: 'Tipo de documento' },
                { campo: 'numeroDocumento', valor: numeroDocumento, mensaje: 'Número de documento' },
                { campo: 'direccion', valor: direccion, mensaje: 'Dirección' },
                { campo: 'correoProveedor', valor: correoProveedor, mensaje: 'Correo electrónico del proveedor' },
                { campo: 'prestadorBienesServicios', valor: prestadorBienesServicios, mensaje: 'Prestador de bienes/servicios' },
                { campo: 'categoria', valor: categoria, mensaje: 'Categoría' },
                { campo: 'evaluador', valor: evaluador, mensaje: 'Evaluador' },
                { campo: 'cargo', valor: cargo, mensaje: 'Cargo' },
                { campo: 'responsableVisita', valor: responsableVisita, mensaje: 'Responsable de la visita' },
                //{ campo: 'responsableRecibir', valor: responsableRecibir, mensaje: 'Responsable de recibir' },
            ];

            for (let campo of camposRequeridos) {
                if (!campo.valor || campo.valor.trim() === '') {
                    swal({
                        title: 'Se debe de firmar el documento!',
                        text: `Campo faltante: ${campo.mensaje}`,
                        icon: 'warning',
                    });
                    setLoading(false)
                    return false;
                }
            }

            if (firmaFile == null) {
                swal("Se debe de firmar el documento!", "Firma , guarda y envia!", "warning");
                setLoading(false)
                return;
            }
            const jsonData = JSON.stringify(tableData);
            var raw = JSON.stringify({
                "datos": `${jsonData}`,
                "nombreproveedor": `${nombreProveedor}`,
                "tipodocumento": `${tipoDocumento}`,
                "numeroducumento": `${numeroDocumento}`,
                "direccion": `${direccion}`,
                "correoelectronico": `${correoProveedor}`,
                "prestadorbienes": `${prestadorBienesServicios}`,
                "categoria": `${categoria}`,
                "evaluador": `${evaluador}`,
                "cargo": `${cargo}`,
                "firma1": `${firmaFile}`,
                "firma2": `${firmaFile2}`,
                "periodoEvaluado": `${periodoEvaluado}`,
                "responsable": `${responsableVisita}`,
                "responsable2": `${responsableRecibir}`,
                "obsersaciones": `${observacion}`,
                "favorabl": `${((totalPoints / ((evaluations.length) * 3)) * 100).toFixed(2)}`,
                "favorable": `${(((totalPoints / ((evaluations.length) * 3)) * 100).toFixed(2) > 80 ? "FAVORABLE" : ((totalPoints / ((evaluations.length) * 3)) * 100).toFixed(2) > 60
                    && ((totalPoints / ((evaluations.length) * 3)) * 100).toFixed(2) <= 80 ? "FAVORABLE CONDICIONAL" : "NO FAVORABLE")}`

            });
            console.log(firmaFile)
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };
            fetch("https://consumos.talsa.com.co/api/talsa/pdf/v1/pdfcalidad", requestOptions)
                .then(response => {
                    if (response.status == 200) {
                        return response.text(); // Return the response text to the next then
                    } else {

                        setLoading(false)
                        swal("Error", "El documento no fue creado con exito", "error");
                        throw new Error('El documento no fue creado con exito');
                    }
                })
                .then(responseText => {
                    const responseJson = JSON.parse(responseText);
                    const base64 = responseJson.base64;
                    console.log(base64)
                    // Here you have the base64 string
                    EvaluacionProveedor(codigoInternoProveedor, nombreProveedor, tipoDocumento, numeroDocumento, direccion, prestadorBienesServicios,
                        evaluador, cargo, categoria, fechaEvaluacion, periodoEvaluado, correoProveedor, jsonData, observacion).then((result) => {
                            ingresoDOCXWorkmanagerFlujo(base64, result.workflowId, "evaluacioncalidad").then(() => {
                                swal("Exitoso", "El documento fue creado con exito", "success");
                            })
                        })
                }).then(result => console.log(result))
                .catch(error => console.log('error', error));

        } catch (error) {
            console.error('Error al descargar el archivo:', error.message);
        }
    };

    return (
        <EvaluationContainer>
            <TableContainerStyled component={Paper} >
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                <Typography>CÓDIGO INTERNO DEL PROVEEDOR:</Typography>
                                <TextField fullWidth={25} required
                                    type="text"
                                    value={codigoInternoProveedor}
                                    onChange={handleCodigoInternoProveedorChange}
                                />
                            </TableCell>
                            <TableCell>
                                <Typography>NOMBRE DEL PROVEEDOR:</Typography>
                                <TextField fullWidth={25} required
                                    type="text"
                                    value={nombreProveedor}
                                    onChange={handleNombreProveedorChange}
                                />
                            </TableCell>
                            <TableCell>
                                <Typography>TIPO DE DOCUMENTO:</Typography>
                                <TextField fullWidth={25} required
                                    type="text"
                                    value={tipoDocumento}
                                    onChange={handleTipoDocumentoChange}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Typography>NÚMERO DE DOCUMENTO:</Typography>
                                <TextField fullWidth={25} required
                                    type="text"
                                    value={numeroDocumento}
                                    onChange={handleNumeroDocumentoChange}
                                />
                            </TableCell>
                            <TableCell>
                                <Typography>DIRECCIÓN:</Typography>
                                <TextField fullWidth={25} required
                                    type="text"
                                    value={direccion}
                                    onChange={handleDireccionChange}
                                />
                            </TableCell>
                            <TableCell>
                                <Typography>PRESTADOR DE BIENES O SERVICIOS:</Typography>
                                <TextField fullWidth={25} required
                                    type="text"
                                    value={prestadorBienesServicios}
                                    onChange={handlePrestadorBienesServiciosChange}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Typography>EVALUADOR:</Typography>
                                <TextField fullWidth={25} required
                                    type="text"
                                    value={evaluador}
                                    onChange={handleEvaluadorChange}
                                />
                            </TableCell>
                            <TableCell>
                                <Typography>CARGO:</Typography>
                                <TextField fullWidth={25} required
                                    type="text"
                                    value={cargo}
                                    onChange={handleCargoChange}
                                />
                            </TableCell>
                            <TableCell>
                                <Typography>CATEGORÍA A LA QUE PERTENECE:</Typography>
                                <TextField fullWidth={25} required
                                    type="text"
                                    value={categoria}
                                    onChange={handleCategoriaChange}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Typography>FECHA DE EVALUACIÓN:</Typography>
                                <TextField fullWidth={25} required
                                    type="date"
                                    value={fechaEvaluacion}
                                    onChange={handleFechaEvaluacionChange}
                                />
                            </TableCell>
                            <TableCell>
                                <Typography>PERÍODO EVALUADO:</Typography>
                                <TextField fullWidth={25} required
                                    type="text"
                                    value={periodoEvaluado}
                                    onChange={handlePeriodoEvaluadoChange}
                                />
                            </TableCell>
                            <TableCell>
                                <Typography>CORREO ELECTRÓNICO DEL PROVEEDOR:</Typography>
                                <TextField fullWidth={25}
                                    type="text"
                                    value={correoProveedor}
                                    onChange={handleCorreoProveedorChange}
                                />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainerStyled>


            <TableContainer component={Paper} style={{ width: "100%", border: "1px solid", margin: "auto", padding: "0px" }}>
                <Table >
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <h4>SISTEMA DE PUNTUACIÓN</h4>
                            </TableCell>
                            <TableCell>
                                <h4>Categoría</h4>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                1
                            </TableCell>
                            <TableCell>
                                No cumple
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                2
                            </TableCell>
                            <TableCell>
                                Cumple parcialmente
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                3
                            </TableCell>
                            <TableCell>
                                Cumple
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <Container>
                <TableContainerStyled component={Paper} style={{ maxHeight: "400px", overflowY: "auto" }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCellHead style={{ position: "sticky", top: 0, zIndex: 1 }}>Categoría</TableCellHead>
                                <TableCellHead style={{ position: "sticky", top: 0, zIndex: 1 }}>Preguntas</TableCellHead>
                                <TableCellHead style={{ position: "sticky", top: 0, zIndex: 1 }}>Evaluación</TableCellHead>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {evaluationData.map((category, categoryIndex) => (
                                <React.Fragment key={categoryIndex}>
                                    {category.questions.map((question, questionIndex) => (
                                        <TableRow key={`${categoryIndex}-${questionIndex}`}>
                                            {questionIndex === 0 && (
                                                <TableCellHead style={{ textAlign: "center" }} rowSpan={category.questions.length}>{category.category}</TableCellHead>
                                            )}
                                            <TableCellBody>{question.question}</TableCellBody>
                                            <TableCellBody>
                                                <SelectFormControl>
                                                    <Select required
                                                        value={evaluations[question.index]}
                                                        onChange={(event) => handleEvaluationChange(question.index, event.target.value)}
                                                    >
                                                        <MenuItem value={1}>1</MenuItem>
                                                        <MenuItem value={2}>2</MenuItem>
                                                        <MenuItem value={3}>3</MenuItem>
                                                    </Select>
                                                </SelectFormControl>
                                            </TableCellBody>
                                        </TableRow>
                                    ))}
                                </React.Fragment>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainerStyled>
            </Container>



            <TableContainerStyled component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCellHead>Observación</TableCellHead>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableCellBody>
                            <TextareaAutosize
                                style={{ width: '100%', resize: 'none' }}
                                value={observacion}
                                onChange={handleObservacionChange}
                                rows={8} // Puedes ajustar el número de filas según tus necesidades
                            />
                        </TableCellBody>
                    </TableBody>
                </Table>
            </TableContainerStyled>




            <br></br>
            <TableContainer component={Paper} >
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell >Calificación</TableCell>
                            <TableCell >Puntaje</TableCell>
                            <TableCell >Acción a seguir</TableCell>
                            <TableCell >Control</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {asociados.map((asociado, index) => (
                            <TableRow key={index}>
                                <TableCell>{asociado.tipo}</TableCell>
                                <TableCell>{asociado.puntaje}</TableCell>
                                <TableCell>{asociado.accion}</TableCell>
                                <TableCell>{asociado.control}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Typography variant="h6">Calificación: {((totalPoints / ((evaluations.length) * 3)) * 100).toFixed(2)} %</Typography>
            <Typography variant="h6">Calificación: {
                (((totalPoints / ((evaluations.length) * 3)) * 100).toFixed(2) > 80 ? "FAVORABLE" : ((totalPoints / ((evaluations.length) * 3)) * 100).toFixed(2) > 60
                    && ((totalPoints / ((evaluations.length) * 3)) * 100).toFixed(2) <= 80 ? "FAVORABLE CONDICIONAL" : "NO FAVORABLE")
            }</Typography>


            <div>
                <TextField
                    placeholder='RESPONSABLE DE EJECUTAR LA EVALUACIÓN'
                    style={{ width: "350px", padding: "15px" }}
                    value={responsableVisita}
                    onChange={(e) =>
                        setResponsableVisita(e.target.value)
                    }
                />
                <SignatureCanvas
                    canvasProps={{
                        width: 550,
                        height: 150,
                        style: { "border": "1px solid #000000" }
                    }}
                    minWidth={1}
                    maxWidth={1}
                    penColor="black"
                    ref={sigPad}
                />
                <br /><Button onClick={() => {
                    sigPad.current.clear()
                    setFirmaFile(null)
                }}
                    variant="contained"
                    component="label"
                    color="primary"
                    style={{ margin: "10px" }}>
                    Limpiar     </Button>
                <Button variant="contained"
                    component="label"
                    color="primary"
                    style={{ margin: "10px" }} onClick={() => {
                        sumPoints()
                        const canvas = sigPad.current.getCanvas();
                        const base64Data = canvas.toDataURL(); // Obtener el valor base64 de la imagen dibujada
                        setFirmaFile(base64Data)
                        swal("Gracias", "Firma ingresada con exito", "success");
                    }}>Guardar</Button>
            </div>




            {/* <div>
                <TextField
                    placeholder='RESPONSABLE DE EJECUTAR LA EVALUACIÓN'
                    style={{ width: "350px", padding: "15px" }}
                    value={responsableRecibir}
                    onChange={(e) =>
                        setResponsableRecibir(e.target.value)
                    }
                />
                <SignatureCanvas
                    canvasProps={{
                        width: 550,
                        height: 150,
                        style: { "border": "1px solid #000000" }
                    }}
                    minWidth={1}
                    maxWidth={1}
                    penColor="black"
                    ref={sigPad2}
                />
                <br /><Button onClick={() => {
                    sigPad2.current.clear()
                    setFirmaFile2(null)
                }}
                    variant="contained"
                    component="label"
                    color="primary"
                    style={{ margin: "10px" }}>
                    Limpiar     </Button>
                <Button variant="contained"
                    component="label"
                    color="primary"
                    style={{ margin: "10px" }} onClick={() => {
                        sumPoints()
                        const canvas = sigPad2.current.getCanvas();
                        const base64Data = canvas.toDataURL(); // Obtener el valor base64 de la imagen dibujada
                        setFirmaFile2(base64Data)
                        swal("Gracias", "Firma ingresada con exito", "success");
                    }}>Guardar</Button>
            </div>*/}




            <Alert severity="warning">Debe de firmar el formulario y luego darle al boton enviar información.</Alert>

            <Button variant="contained" color="primary"
                disabled={loading} onClick={handleClick} FormControl>
                Enviar información
            </Button>

        </EvaluationContainer>
    );
}
