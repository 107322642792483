import * as React from 'react';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Textarea from '@mui/joy/Textarea';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import Stack from '@mui/joy/Stack';
import Add from '@mui/icons-material/Add';
import Typography from '@mui/joy/Typography';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { CambiosComentarioFacturas, facturasget } from '../Componentes/FunctionLogin.js'

export default function BasicModalDialogFacturas({ factura, proveedor,text,boton,tipo }) {
  let date = new Date();
  const [open, setOpen] = React.useState(false);
  const [fechainicial, setfechainicial] = React.useState(dayjs(date.toISOString().split('T')[0]));
  const [descripcion, setDescripcion] = React.useState("");


  return (
    <React.Fragment>
      <Button
        variant="outlined"
        color="neutral"
        startDecorator={<Add />}
        onClick={() => setOpen(true)}
      >
        {boton}
      </Button>

      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog
          aria-labelledby="basic-modal-dialog-title"
          aria-describedby="basic-modal-dialog-description"
          sx={{ maxWidth: 500 }}
        >
          <Typography id="basic-modal-dialog-title" component="h2">
            {factura} 
          </Typography>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              CambiosComentarioFacturas(dayjs(fechainicial).format('YYYY-MM-DD'), descripcion, factura, proveedor ).then((result) => {
              });
              setOpen(false);
            }}
          >
            <Stack spacing={2}>
              <FormControl>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={['DatePicker', 'DatePicker']}>
                    <DatePicker disabled label="Fecha de comentario" value={fechainicial}
                      onChange={(newValue) => { setfechainicial(newValue); }} />
                  </DemoContainer>
                </LocalizationProvider>
              </FormControl>
              <FormControl>
                <FormLabel>Comentario sobre la factura: {factura}</FormLabel>
                <Textarea minRows={2} required value={descripcion} onChange={(e) => {
                  setDescripcion(e.target.value)
                }} />
              </FormControl>
              <Button type="submit">Enviar solicitud</Button>
            </Stack>
          </form>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}