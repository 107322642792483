import * as React from 'react';
import { Container } from '@mui/system';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import TablePagination from '@mui/material/TablePagination';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import { Select, MenuItem, Button } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import Box from "@mui/material/Box";
import { ordenesProveedor } from '../Componentes/FunctionLogin.js'
import { myLocaleText } from '../Componentes/FunctionLogin.js'
import '../css/menu.css'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { Card } from '@mui/material';
import Alert from '@mui/material/Alert';
import FilterListIcon from '@mui/icons-material/FilterList';
import { DataGrid, GridColumnHeaderTitle } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next'
import Modal from 'react-modal';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import BasicModalDialog from '../Componentes/BasicModalDialog.js';
// mock

// ----------------------------------------------------------------------
const XLSX = require('xlsx');

export default function Proyectlist() {

    const [t, i18n] = useTranslation("global");
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "#830000",
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));
    let date = new Date();
    date.setMonth(date.getMonth() - 1);
    const opciones = { year: 'numeric', month: 'long', day: 'numeric' };
    const opcionesMes = { year: 'numeric', month: 'long' };
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [nit, setNit] = React.useState("");
    const [orden, setOrden] = React.useState("");
    const [fechainicial, setfechainicial] = React.useState(dayjs(date.toISOString().split('T')[0]));
    const [fechafinal, setfechafinal] = React.useState(dayjs(new Date().toISOString().split('T')[0]));
    const [VectorFinal, setVectorFinal] = React.useState([]);
    const [VectorV, setVectorV] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [contar, setContar] = React.useState("Cargar");
    const [carga, setCarga] = React.useState(1);
    const [age, setAge] = React.useState(" ");
    const [sede, setSede] = React.useState(" ");
    const [filtro, setFiltro] = React.useState(false);

    const [totalRegistros, setTotalRegistros] = React.useState(0);
    const [registrosATiempo, setRegistrosATiempo] = React.useState(0);
    const [registrosAtrasados, setRegistrosAtrasados] = React.useState(0);
    const [registrosAnticipados, setRegistrosAnticipados] = React.useState(0);
    const [porcentajeATiempo, setPorcentajeATiempo] = React.useState(0);



    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
    );

    const labels = ['Estados'];

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Estados de pedidos',
            },
        },
    };

    const data = {
        labels,
        datasets: [
            {
                label: `${t("ordenes.aTiempo")}`,
                data: labels.map((datosMes) => VectorFinal.filter((dato) => {
                    return dato.estado.includes("A TIEMPO") === true
                }).length),
                backgroundColor: 'yellow',
            }, {
                label: `${t("ordenes.atrasado")}`,
                data: labels.map((datosMes) => VectorFinal.filter((dato) => {
                    return dato.estado.includes("ATRASADO") === true
                }).length),
                backgroundColor: 'red',
            }, {
                label: `${t("ordenes.anticipado")}`,
                data: labels.map((datosMes) => VectorFinal.filter((dato) => {
                    return dato.estado.includes("ANTICIPADO") === true
                }).length),
                backgroundColor: 'green',
            },
        ],
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
    };

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: '20px',
            borderRadius: '10px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            width: '100%',
            maxWidth: '1000px',
            height: "600px"
        },
        overlay: {
            zIndex: '1000'
        }
    };



    React.useEffect(() => {
        setVectorFinal([])
        if (VectorV.length > 0) {
            setVectorFinal([])
        }
    }, [VectorV])

    const [modalIsOpen, setIsOpen] = React.useState(false);

    React.useEffect(() => {
        if (localStorage.getItem('showOrderPopup') === null) {
            localStorage.setItem('showOrderPopup', 'true');
        }
        if (localStorage.getItem('showOrderPopup') === 'true') {
            setIsOpen(true);
        }
    }, []);

    const closeModal = () => {
        localStorage.setItem('showOrderPopup', 'false');
        setIsOpen(false);
    };


    return (
        <Container className="ContenedorTabla" style={{ backgroundColor: "" }} maxWidth={"100%"}>
            <div style={{ zIndex: "20" }}>
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel="PDF Popup"
                >
                    <h2>Bienvenido</h2>
                    <Button
                        onClick={closeModal}
                        style={{
                            marginTop: '10px',
                            padding: '10px',
                            borderRadius: '5px',
                            backgroundColor: '#bd1f34',
                            color: '#fff',
                            border: 'none',
                            margin: "10px"
                        }}>
                        Cerrar
                    </Button>
                    <Button
                        onClick={() => {
                            window.open('https://drive.google.com/file/d/1lhlRKjmo725WGXoiAEwQydn64uH3ZxNO/view?usp=sharing')
                        }}
                        style={{
                            marginTop: '10px',
                            padding: '10px',
                            borderRadius: '5px',
                            backgroundColor: '#bd1f34',
                            color: '#fff',
                            border: 'none',
                            margin: "10px"
                        }}>
                        Descargar  RUT
                    </Button>
                    <iframe
                        src={localStorage.lenguaje == "en" ? "https://drive.google.com/file/d/1wGbVU1P-S9-y8DUh_tSLDyoSHmPZStzF/preview" : "https://drive.google.com/file/d/1tdiMim25HdrNGbIdP-RKrFM5TC4Z4a_4/preview"}
                        style={{ width: '100%', height: 'calc(100% - 80px)' }}
                        title="PDF Document"
                    ></iframe>

                </Modal>
            </div>


            <Container style={{ backgroundColor: "", marginLeft: "0px", width: "100%", maxWidth: "100%" }} className="ContenedorBuscadorTabla">
                <Paper component="form" className='BuscadorTabla' elevation={20}
                    style={{ textAlign: "center" }}
                    sx={{
                        p: '2px 4px', display: 'flex', alignItems: 'center',
                    }}>
                    <InputBase style={{ backgroundColor: "" }} value={orden} onInputCapture={(event, newInputValue) => {
                        setOrden(event.target.value)
                    }}
                        sx={{ ml: 1, flex: 1 }}
                        placeholder={t("ordenes.buscarorden")}
                        inputProps={{ 'aria-label': 'Buscar' }} />

                    <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />

                    <IconButton color="primary" title={t("ordenes.buscar")} sx={{ p: '10px' }} aria-label="directions" onClick={() => {
                        setCarga(2)
                        let odenstring = "";
                        let sede2 = " " + sede;
                        if (orden !== "") {
                            odenstring = "and CPO_ID eq '" + orden + "'"
                        }
                        if (sede2 == " ") {
                            sede2 = " and CITM_LFCYCLE_ST ne '8'"
                        }
                        ordenesProveedor(new Date(fechainicial.$d).toISOString().split('T')[0], new Date(fechafinal.$d).toISOString().split('T')[0], age, sede2, odenstring).then((result) => {
                            setVectorFinal(result)
                            setCarga(1)
                        });
                    }}>

                        <PersonSearchIcon />
                    </IconButton>
                    <IconButton color="primary" title={t("ordenes.export")} sx={{ p: '10px' }} aria-label="directions" onClick={() => {
                        const worksheet = XLSX.utils.json_to_sheet(VectorFinal);
                        const workbook = XLSX.utils.book_new();
                        XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');
                        XLSX.writeFile(workbook, 'data.xlsx');
                    }}>
                        <SystemUpdateAltIcon />
                    </IconButton>
                    <IconButton color="primary" sx={{ p: '10px' }} title={t("ordenes.filtro")} aria-label="directions" onClick={() => {
                        setFiltro(!filtro)
                    }}>
                        <FilterListIcon />
                    </IconButton>
                </Paper>
                <Paper component="form" className={filtro === true ? 'BuscadorTabla' : "BuscadorTablaOculto"} elevation={20}
                    style={{ textAlign: "center" }}
                    sx={{
                        p: '2px 4px', display: 'flex', margin: "5px", alignItems: 'center',
                    }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker', 'DatePicker']}>
                            <DatePicker label={t("ordenes.fechainicial")} value={fechainicial}
                                onChange={(newValue) => { setfechainicial(newValue); }} />
                            <DatePicker
                                label={t("ordenes.fechafinal")}
                                value={fechafinal}
                                onChange={(newValue) => { setfechafinal(newValue); }}
                            />
                        </DemoContainer>
                    </LocalizationProvider>
                </Paper>
                <Paper component="form" className={filtro === true ? 'BuscadorTabla' : "BuscadorTablaOculto"} elevation={20}
                    style={{ textAlign: "center" }}
                    sx={{
                        p: '2px 4px 4px 4px', margin: "5px", display: 'flex', alignItems: 'center',
                        borderBottom: "0.2em solid #c82339"
                    }}>

                    <Select style={{ width: "45%" }}
                        labelId="demo-simple-select-autowidth-label"
                        id="demo-simple-select-autowidth"
                        value={age}
                        onChange={(e) => {
                            setAge(e.target.value);
                        }}
                        label="En preparación">
                        <MenuItem value={" "}>{t("ordenes.totalestados")} </MenuItem>
                        <MenuItem value={" and CITM_LFCYCLE_ST eq '10' "}>{t("ordenes.facturacontabilizada")} </MenuItem>
                        <MenuItem value={" and CITM_LFCYCLE_ST eq '6' "}>{t("ordenes.pendientedeentrega")} </MenuItem>
                        <MenuItem value={" and CITM_LFCYCLE_ST eq '8' "}>{t("ordenes.cancelado")} </MenuItem>
                        <MenuItem value={" and CITM_LFCYCLE_ST eq '9' "}>{t("ordenes.entregado")} </MenuItem>
                    </Select>

                    <Select style={{ width: "45%" }}
                        labelId="demo-simple-select-autowidth-label"
                        id="demo-simple-select-autowidth"
                        value={sede}
                        onChange={(e) => {
                            setSede(e.target.value);
                        }}
                        label="En preparación">
                        <MenuItem value={' '}>{t("ordenes.todaslassedes")}</MenuItem>
                        <MenuItem value={"and ((CRECEIVING_SITE eq 'CC1101')  or  (CRECEIVING_SITE eq 'CC1103'))"}>Itagüi</MenuItem>
                        <MenuItem value={"and ((CRECEIVING_SITE eq 'CC1201')  or  (CRECEIVING_SITE eq 'CC1203'))"}>Bogotá</MenuItem>
                        <MenuItem value={"and ((CRECEIVING_SITE eq 'CC1301')  or  (CRECEIVING_SITE eq 'CC1303'))"}>Cali</MenuItem>
                        <MenuItem value={"and ((CRECEIVING_SITE eq 'CC1401')  or  (CRECEIVING_SITE eq 'CC1403'))"}>Barranquilla</MenuItem>
                        <MenuItem value={"and ((CRECEIVING_SITE eq 'CC1501')  or  (CRECEIVING_SITE eq 'CC1503'))"}>Bucaramanga</MenuItem>
                        <MenuItem value={"and ((CRECEIVING_SITE eq 'CC1601')  or  (CRECEIVING_SITE eq 'CC1603'))"}>Dosquebradas</MenuItem>
                        <MenuItem value={"and ((CRECEIVING_SITE eq 'CC1701')  or  (CRECEIVING_SITE eq 'CC1703'))"}>Cúcuta</MenuItem>
                    </Select>
                </Paper>
            </Container>
            <Card sx={{ minWidth: 275 }} elevation={20}>
                <CardContent>
                    <Typography sx={{ fontSize: 16 }} color="text.primary" gutterBottom>
                        {t("ordenes.califica")}
                    </Typography>
                    <Typography variant="h5" component="div">



                        <Typography variant="h5" component="div">
                            {VectorFinal.filter((x) => x.estado != "SIN ENTREGA").length === 0 ? (
                                <Alert severity="info">No hay datos disponibles</Alert>
                            ) : (
                                (((VectorFinal.filter((dato) => dato.estado === "ATRASADO").length * 100) / VectorFinal.filter((x) => x.estado != "SIN ENTREGA").length) - 100) * -1 <= 80 ? (
                                    <Alert severity="error">{t("ordenes.noFavorable")} {Math.floor((((VectorFinal.filter((dato) => dato.estado === "ATRASADO").length * 100) / VectorFinal.filter((x) => x.estado != "SIN ENTREGA").length) - 100) * -1)}%</Alert>
                                ) : (((VectorFinal.filter((dato) => dato.estado === "ATRASADO").length * 100) / VectorFinal.filter((x) => x.estado != "SIN ENTREGA").length) - 100) * -1 >= 80.1 && (((VectorFinal.filter((dato) => dato.estado === "ATRASADO").length * 100) / VectorFinal.filter((x) => x.estado != "SIN ENTREGA").length) - 100) * -1 <= 95 ? (
                                    <Alert severity="warning">{t("ordenes.favorableCondicional")} {Math.floor((((VectorFinal.filter((dato) => dato.estado === "ATRASADO").length * 100) / VectorFinal.filter((x) => x.estado != "SIN ENTREGA").length) - 100) * -1)}%</Alert>
                                ) : (
                                    <Alert severity="success">{t("ordenes.favorable")} {Math.floor((((VectorFinal.filter((dato) => dato.estado === "ATRASADO").length * 100) / VectorFinal.filter((x) => x.estado != "SIN ENTREGA").length) - 100) * -1)}%</Alert>
                                )
                            )}
                        </Typography>
                    </Typography>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        {t("ordenes.estimado")}
                    </Typography>
                </CardContent>
            </Card>
            <br />
            <p>{t("ordenes.totalregistros")}:{VectorFinal.length} </p>
            <Paper elevation={20}>
                {carga === 2 ?
                    <Box sx={{ width: '100%' }}>
                        <LinearProgress />
                    </Box> :
                    <p>{t("ordenes.informacion")}</p>
                }
                <div style={{ height: 400, width: '100%' }}>
                    <style>{`
                            .MuiDataGrid-columnHeaderTitle {
                            font-weight: bold;
                            }
                        `}</style>
                    <DataGrid localeText={myLocaleText}
                        rows={VectorFinal}
                        columns={[
                            { field: 'FechaCreacion', headerName: `${t("ordenes.tabla.FechaGeneracion")}`, type: '', width: 160, description: `${t("ordenes.tabla.FechaGeneracion")}`, valueFormatter: (params) => dayjs(params.value).add(1, 'day').format('DD/MM/YYYY'), },
                            { field: 'fechaFactura', headerName: `${t("ordenes.tabla.FechaFactura")}`, type: '', width: 160, description: `${t("ordenes.tabla.FechaFactura")}`, valueFormatter: (params) => dayjs(params.value).add(1, 'day').format('DD/MM/YYYY'), },
                            { field: 'fechaentrega', headerName: `${t("ordenes.tabla.FechaEntrega")}`, type: '', width: 160, description: `${t("ordenes.tabla.FechaEntrega")}`, valueFormatter: (params) => dayjs(params.value).add(1, 'day').format('DD/MM/YYYY'), },
                            {
                                field: 'fechaentregaentrante',
                                headerName: `${t("ordenes.tabla.EntregaReal")}`,
                                type: '',
                                width: 160,
                                description: `${t("ordenes.tabla.EntregaReal")}`,
                                valueFormatter: (params) => {
                                    const formattedDate = dayjs(params.value).add(1, 'day').format('DD/MM/YYYY');
                                    return dayjs(params.value).isValid() ? formattedDate : ''; // Return empty string if the date is invalid
                                },
                            },
                            {
                                field: 'estado', headerName: `${t("ordenes.tabla.Evaluacion")}`, type: '', width: 160, description: `${t("ordenes.tabla.Evalucion")}`,
                                renderCell: (params) => {
                                    let backgroundColor;
                                    switch (params.value) {
                                        case 'ATRASADO':
                                            backgroundColor = 'red';
                                            break;
                                        case 'A TIEMPO':
                                            backgroundColor = 'yellow';
                                            break;
                                        case 'SIN ENTREGA':
                                            backgroundColor = 'orange';
                                            break;
                                        default:
                                            backgroundColor = 'green';
                                            break;
                                    }
                                    return (
                                        <div>
                                            {params.value == "ATRASADO" ? `${t("ordenes.atrasado")}` :
                                                params.value == "A TIEMPO" ? `${t("ordenes.aTiempo")}` :
                                                    params.value == "SIN ENTREGA" ? `${t("ordenes.sinEntrega")}` : `${t("ordenes.anticipado")}`
                                            } <span style={{ backgroundColor }}>◉</span>
                                        </div>
                                    );
                                }
                            },
                            {
                                field: 'EstadoArticulo',headerName: `${t("ordenes.tabla.EstadoArticulo")}`, type: '', width: 160, description: `${t("ordenes.tabla.EstadoArticulo")}`,
                                renderCell: (params) => {
                                    return (
                                        <div>
                                            {params.value === "Enviado" ? `${t("ordenes.tabla.estadoPendiente")}` : `${t("ordenes.tabla.estadoEntregado")}`} 
                                        </div>
                                    );
                                }
                            },
                            {
                                field: 'EstadoPedido', headerName: `${t("ordenes.tabla.EstadoPedido")}`, type: '', width: 160, description: `${t("ordenes.tabla.EstadoPedido")}`,
                                renderCell: (params) => {
                                    return (
                                        <div>
                                            {params.value === "Finalizado" ? `${t("ordenes.tabla.estadoEntregado")}` : `${t("ordenes.tabla.estadoPendiente")}`} 
                                        </div>
                                    );
                                }
                            },
                            { field: 'NumeroPiezaProveedor', headerName: `${t("ordenes.tabla.PartNumber")}`, type: '', width: 160, description: `${t("ordenes.tabla.PartNumber")}` },
                            { field: 'IdPedido', headerName: `${t("ordenes.tabla.OrdenCompra")}`, type: '', width: 160, description: `${t("ordenes.tabla.OrdenCompra")}` },
                            { field: 'PosicionPedido', headerName: `${t("ordenes.tabla.PosicionPedido")}`, type: '', width: 160, description: `${t("ordenes.tabla.PosicionPedido")}`, valueFormatter: ({ value }) => (Number(value).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })) },
                            { field: 'ProductoId', headerName: `${t("ordenes.tabla.SKU")}`, type: '', width: 160, description: `${t("ordenes.tabla.SKU")}` },
                            { field: 'DescProducto', headerName: `${t("ordenes.tabla.DescripcionSKU")}`, type: '', width: 160, description: `${t("ordenes.tabla.DescripcionSKU")}` },
                            { field: 'Unidad', headerName: `${t("ordenes.tabla.Unidad")}`, type: '', width: 160, description: `${t("ordenes.tabla.Unidad")}` },
                            { field: 'Sede', headerName: `${t("ordenes.tabla.LugarEntrega")}`, type: '', width: 160, description: `${t("ordenes.tabla.LugarEntrega")}` },
                            { field: 'PrecioNeto', headerName: `${t("ordenes.tabla.ValorUnitario")}`, type: 'number', width: 160, description: `${t("ordenes.tabla.ValorUnitario")}`, valueFormatter: ({ value }) => (Number(value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })) },
                            { field: 'ValorNeto', headerName: `${t("ordenes.tabla.ValorTotal")}`, type: 'number', width: 160, description: `${t("ordenes.tabla.ValorTotal")}`, valueFormatter: ({ value }) => (Number(value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })) },
                            { field: 'Moneda', headerName: `${t("ordenes.tabla.Moneda")}`, type: '', width: 160, description: `${t("ordenes.tabla.Moneda")}` },
                            { field: 'CantidadEntrega', headerName: `${t("ordenes.tabla.CantidadEntrega")}`, type: 'number', width: 160, description: `${t("ordenes.tabla.CantidadEntrega")}`, valueFormatter: ({ value }) => (Number(value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })) },
                            { field: 'CantidadPedida', headerName: `${t("ordenes.tabla.CantidadPendiente")}`, type: 'number', width: 160, description: `${t("ordenes.tabla.CantidadPendiente")}`, valueFormatter: ({ value }) => (Number(value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })) },
                            { field: 'CantidadFacturada', headerName: `${t("ordenes.tabla.CantidadFacturada")}`, type: 'number', width: 160, description: `${t("ordenes.tabla.CantidadFacturada")}`, valueFormatter: ({ value }) => (Number(value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })) },
                            { field: 'ValorFacturado', headerName: `${t("ordenes.tabla.ValorFacturado")}`, type: 'number', width: 160, description: `${t("ordenes.tabla.ValorFacturado")}`, valueFormatter: ({ value }) => (Number(value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })) },
                            { field: 'Categoria', headerName: `${t("ordenes.tabla.Categoria")}`, type: '', width: 160, description: `${t("ordenes.tabla.Categoria")}` },
                            { field: 'Proveedor', headerName: `${t("ordenes.tabla.Proveedor")}`, type: '', width: 160, description: `${t("ordenes.tabla.Proveedor")}` },
                            {
                                field: 'Solicitud de cambios',
                                headerName: `${t("ordenes.tabla.SolicitudDeCambios")}`, width: 160,
                                renderCell: (params) => (
                                    <BasicModalDialog
                                        oc={params.row.IdPedido}
                                        PosicionPedido={params.row.PosicionPedido}
                                        DescProducto={params.row.DescProducto}
                                        PrecioNeto={params.row.PrecioNeto}
                                        CantidadPedida={params.row.CantidadPedida}
                                        Proveedor={params.row.Proveedor}
                                        ProductoId={params.row.ProductoId}
                                        text={t("ordenes.tabla.SolicitudDeCambios")}
                                        boton={t("cambios.cambios")}
                                    ></BasicModalDialog>
                                ),
                            }
                        ].filter((column) => column.field !== VectorFinal[0]?.columnadato)}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                    />
                </div>
            </Paper>
            <Container style={{ padding: "50px", }}>
                <Bar options={options} data={data} />
            </Container>
        </Container>

    );

}