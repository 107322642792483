import React, { useState } from 'react';
import {
  FormControl,
  Grid,
  InputLabel,
  Typography,
  MenuItem,
  Select,
  TextField
} from '@mui/material';


import comoserproveedor from '../../recursos/comoserproveedor.jpg'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import PersonaNatural from '../Formularios/personaNatural';
import PersonaJuridica from '../Formularios/personaJuridica';
import Container from '@mui/material/Container';
import { useNavigate } from 'react-router-dom';
import { encrypt, decrypt, getIDWorkmanagerRegistroProveedores } from '../FunctionLogin'

export default function FormularioRegistros() {

  const [nitRegistro, setNitRegistro] = useState('');
  const navigate = useNavigate();
  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const codigo = urlParams.get('id');
    const encodedValue = urlParams.get('id');
    decrypt(decodeURIComponent(encodedValue)).then((result) => {
      if (result === "") {
        navigate("/proveedores/cerrar")
      }
      setNitRegistro(result)
      getIDWorkmanagerRegistroProveedores(result).then((resultv2) => {
        if (resultv2.length >= 1) {
          navigate("/proveedores/cerrar")
        }
      })
    });
    if (codigo === null) {
      navigate("/proveedores/cerrar")
    }
  }, [])





  const procesos = [
    'ADMINISTRATIVO Y GESTION HUMANA',
    'CADENA DE SUMINISTRO',
    'CLIENTE',
    'COMERCIAL',
    'CONTROL INTERNO',
    'FINANCIERO',
    'GERENCIA',
    'INGENIERIA',
    'MERCADEO',
    'PROVEEDOR',
    'SERVICIO TÉCNICO',
    'TODOS LOS PROCESOS',
  ];
  const sucursales = [
    "ANTIOQUIA",
    "CENTRO",
    "COSTA",
    "CÚCUTA",
    "EJE CAFETERO",
    "OCCIDENTE",
    "SANTANDER",
    "VARIAS",
  ];
  const tiposNegociacion = [
    "CONTADO",
    "CRÉDITO 15 DÍAS",
    "CRÉDITO 30 DÍAS",
    "CRÉDITO 60 DÍAS",
    "OTRO",
  ];

  const tiposNegociacion2 = [
    "ABASTECIMIENTO",
    "FABRICACIÓN",
    "GASTO",
    "LOGÍSTICA",
    "ORDEN DE VENTA",
    "SERVICIO TÉCNICO",
  ];


  const [tipoPersona, setTipoPersona] = useState('natural');
  const [solicitante, setSolicitante] = useState('');
  const [proceso, setProceso] = useState('ADMINISTRATIVO Y GESTION HUMANA');
  const [sucursal, setSucursal] = useState('ANTIOQUIA');
  const [servicio, setServicio] = useState('');
  const [tipoNegociacion, setTipoNegociacion] = useState('CONTADO');
  const [tipoNegociacion2, setTipoNegociacion2] = useState('ABASTECIMIENTO');
  const [nombreDiligenciaFormato, setNombreDiligenciaFormato] = useState('');
  const [cargoDiligenciaFormato, setCargoDiligenciaFormato] = useState('');



  const handleTipoPersonaChange = (event) => {
    setTipoPersona(event.target.value);
  };

  const handleSolicitanteChange = (event) => {
    setSolicitante(event.target.value);
  };

  const handleProcesoChange = (event) => {
    setProceso(event.target.value);
  };

  const handleSucursalChange = (event) => {
    setSucursal(event.target.value);
  };

  const handleServicioChange = (event) => {
    const value = event.target.value;
    const regex = /^[a-zA-Z\s]*$/;
    if (regex.test(value)) {
      setServicio(event.target.value);
    }
  };

  const handleTipoNegociacionChange = (event) => {
    setTipoNegociacion(event.target.value);
  };

  const handleTipoNegociacionChange2 = (event) => {
    setTipoNegociacion2(event.target.value);
  };



  const handCargoDiligenciaFormato = (event) => {
    setCargoDiligenciaFormato(event.target.value);
  };

  const HandMombreDiligenciaFormato = (event) => {
    setNombreDiligenciaFormato(event.target.value);
  };



  return (

    <Container maxWidth="sm" style={{ maxWidth: 1400, padding: "20px" }}>
      <Card style={{ maxWidth: "100%" }}>
        <CardMedia
          sx={{ height: 550 }}
          image={comoserproveedor}
          title="green iguana"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Registro de proveedores
          </Typography>
          <div>
            <FormControl fullWidth>
              <Typography variant="h6">Tipo de persona</Typography>
              <Select
                labelId="tipo-persona-label"
                id="tipo-persona"
                value={tipoPersona}
                onChange={handleTipoPersonaChange}>
                <MenuItem value="natural">Persona natural</MenuItem>
                <MenuItem value="juridica">Persona jurídica</MenuItem>
              </Select>
            </FormControl>
            <br></br>

            <br />
            <Grid container spacing={2}>

              <Grid item xs={12} sm={6}>
                <InputLabel >Nombres y apellidos de quien diligencia el formato</InputLabel>
                <TextField
                  fullWidth
                  value={nombreDiligenciaFormato}
                  onChange={HandMombreDiligenciaFormato}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputLabel >Cargo de quien diligencia el formato</InputLabel>
                <TextField
                  fullWidth
                  value={cargoDiligenciaFormato}
                  onChange={handCargoDiligenciaFormato} HandMombreDiligenciaFormato
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <InputLabel >Departamento</InputLabel>
                <Select
                  fullWidth
                  label="Sucursal"
                  value={sucursal}
                  onChange={handleSucursalChange}
                >
                  {sucursales.map((sucursal) => (
                    <MenuItem key={sucursal} value={sucursal}>
                      {sucursal}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>

              <Grid item xs={12} sm={6}>
                <InputLabel >Servicio a prestar</InputLabel>
                <TextField
                  fullWidth
                  value={servicio}
                  onChange={handleServicioChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>

                <InputLabel >Condiciones de pago</InputLabel>
                <Select
                  fullWidth
                  label="Tipo de negociación"
                  value={tipoNegociacion}
                  onChange={handleTipoNegociacionChange}
                >
                  {tiposNegociacion.map((tipo) => (
                    <MenuItem key={tipo} value={tipo}>
                      {tipo}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
            {nombreDiligenciaFormato != "" && cargoDiligenciaFormato != "" && servicio != "" ? (tipoPersona === 'natural' ?
              <PersonaNatural solicitanteValue={solicitante} procesoValue={proceso}
                sucursalValue={sucursal} servicioValue={servicio} tipoNegociacionValue={tipoNegociacion}
                tipoNegociacion2Value={tipoNegociacion2} idregisto={nitRegistro}
                nombreFormato={nombreDiligenciaFormato}
                cargoFormato={cargoDiligenciaFormato}
                tipoPersonaRegistro={tipoPersona}
              /> : <PersonaJuridica solicitanteValue={solicitante} procesoValue={proceso}
                sucursalValue={sucursal} servicioValue={servicio} tipoNegociacionValue={tipoNegociacion}
                tipoNegociacion2Value={tipoNegociacion2} idregisto={nitRegistro} nombreFormato={nombreDiligenciaFormato}
                cargoFormato={cargoDiligenciaFormato}
                tipoPersonaRegistro={tipoPersona} />) : ""
            }

          </div>
        </CardContent>
        <CardActions>
          <Button size="small">Terminos y condiciones</Button>
        </CardActions>
      </Card>
    </Container>
  );
};
