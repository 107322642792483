import * as React from 'react';
import { Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { vector } from '../FunctionLogin.js'
import { Card, Text } from "@nextui-org/react";
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import carpeta from '../../recursos/carpeta.jpg'

export default function Subcarpetas() {
    const navigate = useNavigate();
    const urlParams = new URLSearchParams(window.location.search);
    const codigo = urlParams.get('codigo');
    const [vectorV] = React.useState(vector.filter((datos) => datos.nombre === codigo)[0].carpeta);
    return (
        <Container className='' maxWidth={"10px"} style={{ display: "flex", flexWrap: "wrap" }}>
            {vectorV.map(item => (
                <Container className='hijosfooterv2' style={{ width: "14.3%", margin: "10px" }} key={Math.floor(Math.random() * 9999999) + 1}>
                    <Card isPressable
                        isHoverable
                        variant="bordered" sx={{ maxWidth: 345 }}>
                        <CardMedia
                            style={{ objectFit: "contain", cursor: "pointer" }}
                            component="img"
                            height="150"
                            image={carpeta}

                            alt="archivo"
                            onClick={() => {
                                navigate("/proveedores/archivos?codigo=" + item.codigo)
                            }}
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h6" title={item.nombre} component="div">
                                {item.nombre}
                            </Typography>
                        </CardContent>
                    </Card>
                </Container>
            ))}
        </Container>
    )
}