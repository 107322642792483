/**
 * La función anterior es una función JavaScript asíncrona que realiza una solicitud POST a una URL específica con algunas cabeceras y una carga JSON, y devuelve la respuesta como una promesa.
 * URL con algunas cabeceras y una carga JSON, y devuelve la respuesta como una Promise.
 * @param Url - El parámetro `Url` es una cadena que representa la URL del punto final de la API al que quieres
 * hacer una petición.
 * @returns La función `GetApp` devuelve una Promise que resuelve el resultado de la llamada a la API
 * realizada con la función `fetch`.
 */

export async function GetApp(Url,token) {
    return new Promise((resolve) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("authorization", token);
        const raw = JSON.stringify({
            "url": Url
        });
        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow',
        };
        fetch("https://consumos.talsa.com.co/api/talsa/v1/sap", requestOptions)
            .then(response => response.text())
            .then(result => {
                resolve(result);
            })
            .catch(error => console.log('error', error));
    });
}


