import * as React from 'react';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import Stack from '@mui/joy/Stack';
import HttpsTwoToneIcon from '@mui/icons-material/HttpsTwoTone';
import Typography from '@mui/joy/Typography';
import swal from 'sweetalert';
import {ActualizarDatos} from '../Componentes/FunctionLogin'

export default function ArchivoEvaluacion({ idarchivo }) {
    let date = new Date();
    const [open, setOpen] = React.useState(false);
    const [contra, setContra] = React.useState();


    return (
        <React.Fragment>
            <Button
                variant="outlined"
                color="neutral"
                startDecorator={<HttpsTwoToneIcon />}
                onClick={() => setOpen(true)}
            >
                Cambio de contraseña
            </Button>
            <Modal open={open} onClose={() => setOpen(false)}>
                <ModalDialog
                    aria-labelledby="basic-modal-dialog-title"
                    aria-describedby="basic-modal-dialog-description"
                    sx={{ maxWidth: 500 }}>
                    <form
                        onSubmit={(event) => {
                            event.preventDefault();
                            ActualizarDatos(localStorage.Proveedor, contra).then((resultActualizar) => {
                                swal("Correcto!", "Datos enviados con exito", "success");
                                setOpen(false);
                            });
                            setOpen(false);
                        }}>
                        <Stack spacing={2}>
                        <FormControl>
                                <Typography id="basic-modal-dialog-description" textColor="text.primary">
                                    Cambio de contraseña
                                </Typography>
                                <FormLabel>Proveedor
                                </FormLabel>
                                <Input value={localStorage.Tproveedor}
                                    autoFocus required disabled />
                                <FormLabel>Nueva contraseña
                                </FormLabel>
                                <Input value={contra} onChange={(e) => {
                                    setContra(e.target.value)
                                }} autoFocus required />
                            </FormControl>
                            <Button type="submit">Actualizar contraseña</Button>
                        </Stack>
                    </form>
                </ModalDialog>
            </Modal>
        </React.Fragment>
    );
}