import { useRoutes, useNavigate } from 'react-router-dom';
import Login from './Componentes/Login';
import Ordenes from './Informes/ordenes';
import OrdenesData from './Informes/ordenesData';
import Facturassap from './Informes/facturas';
import Menuscitalsa from './Componentes/Menuscitalsa';
import Encrypt from './Componentes/Encrypt';
import Contrasena from './Componentes/contrasena';
import Documentacion from './Componentes/documentacion';
import CarpetasYear from './Componentes/Carpetas/carpetasyear';
import Subcarpetas from './Componentes/Carpetas/subcarpetas';
import FormularioRegistros from './Componentes/Formularios/formularioRegistros';
import FormularioRegistrosinternacional from './Componentes/Formularios/formularioInternacional';
import Vista from './Componentes/Carpetas/vistapdf';
import Evalacion from './Componentes/Evalucion/evaluacion';
import EvalacionVisita from './Componentes/Evalucion/evaluacionVisita';
import EvalacionVisitaEn from './Componentes/Evalucion/evaluacionVisitaen';
import EvalacionCalidad from './Componentes/Evalucion/evaluacionCalidad';
import EvalacionCalidaden from './Componentes/Evalucion/evaluacionCalidaden';
import EvaluacionPDF from './Componentes/Evalucion/evaluacionPDF';
import Consultaevaluacion from './Componentes/Evalucion/consultaevaluacion';
import ConsultaevaluacionAll from './Componentes/Evalucion/consultall';
import { decrypt } from './Componentes/FunctionLogin'
import * as React from 'react';
import CambioProveedor from './Componentes/cambioproveedor';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next'

import InspeccionFormulario from  './Componentes/FormularioNex/PrimerFormulario'


export default function Router() {

  const [t, i18n] = useTranslation("global");
  const navigate = useNavigate();
  const link = "/proveedores"
  const ulrPermitidas = ["registro", "registrointernacional", "contraseña"];
  React.useEffect(() => {
    let validar = true;
    ulrPermitidas.forEach(element => {
      if (window.location.toString().includes(element)) {
        validar = false;
      }
    });
    if (validar == true) {
      validarSesion();
    }
  }, []);

  const validarSesion = () => {
    let id = Cookies.get('sessionId');
    if (typeof id === 'undefined') {
      navigate('/proveedores/Cerrar');
    }

    decrypt(id).then((session) => {
      if (session.indexOf("Conectado") !== -1) {
        if (localStorage.workmanagerTipoUsuario == "TECNICO") {
          navigate('/proveedores/documentación');
        } else {
          navigate('/proveedores/órdenes');
        }
      } else {
        navigate('/proveedores/Cerrar');
      }
    })


  };
  return useRoutes([
    {
      path: `${link + "/Cerrar"}`,
      element: <Login />,
    }, {
      path: `${link + "/órdenes"}`,
      element: <Menuscitalsa componet={<OrdenesData />} />,
    }, {
      path: `${link + "/Estado de cuenta"}`,
      element: <Menuscitalsa componet={<Facturassap />} />,
    }, {
      path: `${link + "/Formato F-CS-LG-16"}`,
      element: <Menuscitalsa componet={ <InspeccionFormulario/> } />,
    },
    {
      path: `${link + "/documentación"}`,
      element: <Menuscitalsa componet={<CarpetasYear />} />,
    }, {
      path: `${link + "/archivos"}`,
      element: <Menuscitalsa componet={<Documentacion />} />,
    }, {
      path: `${link + "/carpetas"}`,
      element: <Menuscitalsa componet={<Subcarpetas />} />,
    }, {
      path: `${link + "/registro"}`,
      element: <FormularioRegistros />,
    }, {
      path: `${link + "/registrointernacional"}`,
      element: <FormularioRegistrosinternacional />,
    }
    , {
      path: `${link + "/generar link proveedores"}`,
      element: <Menuscitalsa componet={<Encrypt />} />,
    },
    {
      path: `${link + "/contraseña"}`,
      element: <Contrasena />,
    }, {
      path: `${link + "/evaluación"}`,
      element: <Menuscitalsa componet={<Evalacion />} />,
    }, {
      path: `${link + "/evaluación proveedor"}`,
      element: <Menuscitalsa componet={<Consultaevaluacion />} />,
    }, {
      path: `${link + "/all"}`,
      element: <Menuscitalsa componet={<ConsultaevaluacionAll />} />,
    }, {
      path: `${link + "/evaluacionPDF"}`,
      element: <Menuscitalsa componet={<EvaluacionPDF />} />,
    }, {
      path: `${link + "/visitaproveedores"}`,
      element: <Menuscitalsa componet={<EvalacionVisita />} />,
    }, {
      path: `${link + "/visitaproveedoresen"}`,
      element: <Menuscitalsa componet={<EvalacionVisitaEn />} />,
    }, {
      path: `${link + "/visitacalidad"}`,
      element: <Menuscitalsa componet={<EvalacionCalidad />} />,
    }, {
      path: `${link + "/visitacalidaden"}`,
      element: <Menuscitalsa componet={<EvalacionCalidaden />} />,
    }, {
      path: `${link + "/cambiar proveedor"}`,
      element: <Menuscitalsa componet={<CambioProveedor />} />,
    }, {
      path: `${link + "/vista"}`,
      element: <Menuscitalsa componet={<Vista />} />,
    }
  ]);
}