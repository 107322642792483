import React, { useState } from 'react';
import {
  FormControl,
  Grid,
  InputLabel,
  Typography,
  MenuItem,
  Select,
  TextField
} from '@mui/material';


import comoserproveedor from '../../recursos/comoserproveedoringles.jpg'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import PersonaInternacional from '../Formularios/personaInternacional';
import Container from '@mui/material/Container';
import { useNavigate } from 'react-router-dom';
import { encrypt, decrypt, getIDWorkmanagerRegistroProveedoresInternacional } from '../FunctionLogin'

export default function FormularioRegistros() {
  const [nitRegistro, setNitRegistro] = useState('');
  const navigate = useNavigate();
  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const codigo = urlParams.get('id');
    const encodedValue = urlParams.get('id');
    decrypt(decodeURIComponent(encodedValue)).then((result) => {
      if (result === "") {
        navigate("/proveedores/cerrar")
      }
      setNitRegistro(result)
      getIDWorkmanagerRegistroProveedoresInternacional(result).then((resultv2)=>{
         if(resultv2.length >= 1){
           navigate("/proveedores/cerrar")
         }
       })
    });
    if (codigo === null) {
      navigate("/proveedores/cerrar")
    }
  }, [])
  




  const procesos = [
    'ADMINISTRATIVO Y GESTION HUMANA',
    'CADENA DE SUMINISTRO',
    'CLIENTE',
    'COMERCIAL',
    'CONTROL INTERNO',
    'FINANCIERO',
    'GERENCIA',
    'INGENIERIA',
    'MERCADEO',
    'PROVEEDOR',
    'SERVICIO TÉCNICO',
    'TODOS LOS PROCESOS',
  ];
  const sucursales = [
    "ANTIOQUIA",
    "CENTRO",
    "COSTA",
    "CÚCUTA",
    "EJE CAFETERO",
    "OCCIDENTE",
    "SANTANDER",
    "VARIAS",
  ];
  const tiposNegociacion = [
    "CONTADO",
    "CRÉDITO 15 DÍAS",
    "CRÉDITO 30 DÍAS",
    "CRÉDITO 60 DÍAS",
    "OTRO",
  ];

  const tiposNegociacion2 = [
    "ABASTECIMIENTO",
    "FABRICACIÓN",
    "GASTO",
    "LOGÍSTICA",
    "ORDEN DE VENTA",
    "SERVICIO TÉCNICO",
  ];

  const [tipoPersona, setTipoPersona] = useState('natural');
  const [solicitante, setSolicitante] = useState('');
  const [proceso, setProceso] = useState('ADMINISTRATIVO Y GESTION HUMANA');
  const [sucursal, setSucursal] = useState('ANTIOQUIA');
  const [servicio, setServicio] = useState('');
  const [tipoNegociacion, setTipoNegociacion] = useState('CONTADO');
  const [tipoNegociacion2, setTipoNegociacion2] = useState('ABASTECIMIENTO');
  const [nombreDiligenciaFormato, setNombreDiligenciaFormato] = useState('');
  const [cargoDiligenciaFormato, setCargoDiligenciaFormato] = useState('');



  const handleTipoPersonaChange = (event) => {
    setTipoPersona(event.target.value);
  };

  const handleSolicitanteChange = (event) => {
    setSolicitante(event.target.value);
  };

  const handleProcesoChange = (event) => {
    setProceso(event.target.value);
  };

  const handleSucursalChange = (event) => {
    setSucursal(event.target.value);
  };

  const handleServicioChange = (event) => {
    setServicio(event.target.value);
  };

  const handleTipoNegociacionChange = (event) => {
    setTipoNegociacion(event.target.value);
  };

  const handleTipoNegociacionChange2 = (event) => {
    setTipoNegociacion2(event.target.value);
  };
  
  

  const handCargoDiligenciaFormato = (event) => {
    setCargoDiligenciaFormato(event.target.value);
  };

  const HandMombreDiligenciaFormato = (event) => {
    setNombreDiligenciaFormato(event.target.value);
  };



  return (

    <Container maxWidth="sm" style={{ maxWidth: 1400, padding: "20px" }}>
      <Card style={{ maxWidth: "100%" }}>
        <CardMedia
          sx={{ height: 550 }}
          image={comoserproveedor}
          title="green iguana"
        />
        <CardContent>
          <div>
            {<PersonaInternacional solicitanteValue={solicitante} procesoValue={proceso}
              sucursalValue={sucursal} servicioValue={servicio} tipoNegociacionValue={tipoNegociacion}
              tipoNegociacion2Value={tipoNegociacion2} idregisto={nitRegistro}
                nombreFormato ={nombreDiligenciaFormato}
                cargoFormato =   {cargoDiligenciaFormato}
                tipoPersonaRegistro = {tipoPersona}
            />}
          </div>
        </CardContent>
        <CardActions>
          <Button size="small">Terminos y condiciones</Button>
        </CardActions>
      </Card>
    </Container>
  );
};
