import React, { useState } from 'react';
import { Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { vector } from '../FunctionLogin.js'
import { Card, Text } from "@nextui-org/react";
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import carpeta from '../../recursos/carpeta.jpg'
import Archivo from '../subirArchivopdf.js';
import { GetDocumentosRevision } from '../FunctionLogin.js'
import { datosDeArchivoWMRevision } from '../FunctionLogin.js'
import { abrirPdf } from '../FunctionLogin.js'
import pdf from '../../recursos/pdfrojo.jpg'
import { useEffect } from 'react';
import Cambioproveedor  from '../cambioproveedor.js'

import '../../css/carpetasyear.css';

export default function CarpetasYear() {
    const [vectorRevision, setvectorRevision] = useState([]);
    const navigate = useNavigate();
    const [vectorV] = useState(vector);
    useEffect(() => {
        GetDocumentosRevision().then((respuesta) => {
            setvectorRevision(respuesta)
        })
    }, [])

    const Ejecutar =()=>{
       GetDocumentosRevision().then((respuesta) => {
            setvectorRevision(respuesta)
        })
     }
    

    return (
        <Container maxWidth={"20px"}>
            {/*<Cambioproveedor style={{width:"20%"}}></Cambioproveedor>*/}
            <Container className='' maxWidth={"10px"} style={{ display: "flex", flexWrap: "wrap" }}>
                {vectorV.map(item => (
                    <Container className='hijosfooterv2' style={{ width: "14.3%", margin: "10px" }} key={Math.floor(Math.random() * 9999999) + 1}>
                        <Card isPressable
                            isHoverable
                            variant="bordered" sx={{ maxWidth: 345 }}>
                            <CardMedia
                                style={{ objectFit: "contain", cursor: "pointer" }}
                                component="img"
                                height="150"
                                image={carpeta}
                                alt="archivo"
                                onClick={() => {
                                    navigate("/proveedores/carpetas?codigo=" + item.nombre)
                                }}
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    {item.nombre}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Container>
                ))}
                <div>
                    <label style={{ margin: "1px", position: 'absolute', top: "12%", left: "60px", width: '15%', height: '20%', zIndex: 999 }}>
                        <Archivo  Ejecutar={Ejecutar} boton={"Subir Documento"}></Archivo>
                    </label>
                </div>
                            </Container>
            <Container style={{ margin: 20 }}>
                <Typography gutterBottom variant="h6" component="div">
                    Documentos en revisión
                </Typography>
            </Container>
            <Container className='' maxWidth={"10px"} style={{ display: "flex", flexWrap: "wrap" }}>
                {vectorRevision.map(item => (
                    <Container className='hijosfooterv2' style={{ width: "14.3%", margin: "10px" }} key={Math.floor(Math.random() * 9999999) + 1}>
                        <Card isPressable
                            isHoverable
                            variant="bordered" sx={{ maxWidth: 345 }}>
                            <CardMedia
                                style={{ objectFit: "contain", cursor: "pointer" }}
                                component="img"
                                height="150"
                                image={pdf}
                                alt="archivo"
                                onClick={() => {
                                    datosDeArchivoWMRevision(item.id).then((resultado) => {
                                        abrirPdf(resultado[0].FileId).then((result) => {
                                            var b64 = result.Code
                                            var obj = document.createElement('object');
                                            obj.style.width = '100%';
                                            obj.style.height = '842pt';
                                            obj.type = 'application/pdf';
                                            obj.data = 'data:application/pdf;base64,' + b64;
                                            var ventana = window.open();
                                            ventana.document.write('<html><head></head><body></body></html>');
                                            var link = document.createElement('a');
                                            link.innerHTML = 'Si el archivo no se visualiza puede descargarlo desde aquí';
                                            link.download = "archivo.pdf";
                                            link.href = 'data:application/octet-stream;base64,' + b64;
                                            ventana.document.body.appendChild(link);
                                            ventana.document.body.appendChild(obj);
                                        })
                                    })
                                }}
                            />
                            <CardContent>
                                <Typography title={item.id} gutterBottom variant="h10" component="div">
                                    {item.id}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Container>
                ))}
            </Container>
        </Container>
    )
}