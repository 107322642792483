import * as React from 'react';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import Stack from '@mui/joy/Stack';
import Add from '@mui/icons-material/Add';
import Typography from '@mui/joy/Typography';
import { DatosPDF } from '../Componentes/FunctionLogin'
import swal from 'sweetalert';

export default function BasicModalDialog({ boton, Ejecutar }) {
  let date = new Date();
  const [open, setOpen] = React.useState(false);
  const [narchivo, setNArchivo] = React.useState();
  const [archivo, setArchivo] = React.useState();
  const [tipoArchivo, setTipoArchivo] = React.useState();


  return (
    <React.Fragment>
      <Button
        variant="outlined"
        color="neutral"
        startDecorator={<Add />}
        onClick={() => setOpen(true)}
      >
        {boton}
      </Button>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog
          aria-labelledby="basic-modal-dialog-title"
          aria-describedby="basic-modal-dialog-description"
          sx={{ maxWidth: 500 }}>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              const archivoInput = event.target.elements.archivo.files[0];
              const fileReader = new FileReader();
              fileReader.readAsDataURL(archivoInput);
              fileReader.onload = function () {
                const archivoBase64 = fileReader.result;
                console.log(tipoArchivo);
                if (tipoArchivo === "application/pdf") {
                  DatosPDF(localStorage.Tproveedor, narchivo, archivoBase64).then(()=>{
                    Ejecutar();
                  })
                } else {
                  swal("Valide la información, ya que solo se acepta PDF!", "Valide la información!", "warning");
                }
              };
              setOpen(false);
            }}>
            <Stack spacing={2}>
              <FormControl>
                <Typography id="basic-modal-dialog-description" textColor="text.primary">
                  Los documentos pasarán a revisión.
                </Typography>  <Typography id="basic-modal-dialog-description" textColor="text.tertiary">
                  *Solo se aceptan archivos PDF*
                </Typography>
                <FormLabel>Proveedor
                </FormLabel>
                <Input value={localStorage.Tproveedor}
                  autoFocus required disabled />
                <FormLabel>Nombre archivo
                </FormLabel>
                <Input value={narchivo} onChange={(e) => {
                  setNArchivo(e.target.value)
                }} autoFocus required />
              </FormControl>
              <FormControl>
                <FormLabel>Archivo</FormLabel>
                <Input type="file" minRows={2} name='archivo' accept="application/pdf"
                  id='archivo' onChange={(e) => {
                    const file = e.target.files[0];
                    setTipoArchivo(file.type)
                    if (file.type === "application/pdf") {
                      setArchivo(e.target.value)
                    } else {
                      swal("Valide la información, ya que solo se acepta PDF!", "Valide la información!", "warning");
                    }
                  }} required />
              </FormControl>
              <Button type="submit">Enviar solicitud</Button>
            </Stack>
          </form>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}