import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import translate from '../recursos/translate.png';
import { useTranslation } from 'react-i18next';
import { Select, MenuItem } from '@mui/material'; // Import the Select and MenuItem components from Material-UI

export default function Idioma() {
  const [t, i18n] = useTranslation("global");

  const countries = [
    { code: 'en', label: 'English', src: "" },
    { code: 'es', label: 'Español', src: "" },
  ];

  // Estado para almacenar el valor seleccionado del combo
  const [selectedLanguage, setSelectedLanguage] = React.useState(() => {
    const storedLanguage = localStorage.getItem('lenguaje');
    return storedLanguage ? countries.find((country) => country.code === storedLanguage) : countries[0];
  });

  // UseEffect para guardar en localStorage cuando cambie el valor seleccionado
  React.useEffect(() => {
    localStorage.setItem('lenguaje', selectedLanguage.code);
    i18n.changeLanguage(selectedLanguage.code);
  }, [selectedLanguage]);

  return (
    <Select
      style={{ margin: "10px", width: 150, height: 40 }}
      value={selectedLanguage.code} // Usamos el estado para el valor seleccionado
      onChange={(event) => {
        const selectedCode = event.target.value;
        const selectedCountry = countries.find((country) => country.code === selectedCode);
        setSelectedLanguage(selectedCountry);
      }} // Actualizamos el estado al cambiar el valor seleccionado
    >
      {countries.map((country) => (
        <MenuItem key={country.code} value={country.code}>
          {country.label}
        </MenuItem>
      ))}
    </Select>
  );
}
