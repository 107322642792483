
import jsPDF from 'jspdf';
import cabeceraImage from '../../recursos/cabecera.png'; // Ruta de la imagen
import { generatePDF } from '../FuncionPDF'



export default function EvaluationPDF() {

    return (
        <div>
            <button onClick={(() => { generatePDF("2022-03-03") })}>Generar PDF</button>
        </div>
    )
}