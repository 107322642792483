import * as React from 'react';
import { Container } from '@mui/system';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import TablePagination from '@mui/material/TablePagination';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import { Select, MenuItem } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import Box from "@mui/material/Box";
import { ordenesProveedor } from '../Componentes/FunctionLogin.js'
import '../css/menu.css'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { Card } from '@mui/material';
import Alert from '@mui/material/Alert';
import FilterListIcon from '@mui/icons-material/FilterList';



import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
// mock

// ----------------------------------------------------------------------
const XLSX = require('xlsx');

export default function Proyectlist() {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#830000",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  let date = new Date();
  date.setMonth(date.getMonth() - 1);

  const opciones = { year: 'numeric', month: 'long', day: 'numeric' };
  const opcionesMes = { year: 'numeric', month: 'long' };
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [nit, setNit] = React.useState("");
  const [orden, setOrden] = React.useState("");
  const [fechainicial, setfechainicial] = React.useState(dayjs(date.toISOString().split('T')[0]));
  const [fechafinal, setfechafinal] = React.useState(dayjs(new Date().toISOString().split('T')[0]));
  const [VectorFinal, setVectorFinal] = React.useState([]);
  const [VectorV, setVectorV] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [contar, setContar] = React.useState("Cargar");
  const [carga, setCarga] = React.useState(1);
  const [age, setAge] = React.useState(" ");
  const [sede, setSede] = React.useState(" ");
  const [filtro, setFiltro] = React.useState(true);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const labels = ['Estados'];

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Estados de pedidos',
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        label: 'SIN ESTADO',
        data: labels.map((datosMes) => VectorFinal.filter((dato) => {
          return dato.estado.includes("SIN ESTADO") === true
        }).length),
        backgroundColor: 'rgb(102 97 97)',
      }, {
        label: 'A TIEMPO',
        data: labels.map((datosMes) => VectorFinal.filter((dato) => {
          return dato.estado.includes("A TIEMPO") === true
        }).length),
        backgroundColor: 'rgb(0 11 40)',
      }, {
        label: 'ATRASADO',
        data: labels.map((datosMes) => VectorFinal.filter((dato) => {
          return dato.estado.includes("ATRASADO") === true
        }).length),
        backgroundColor: 'rgb(88 0 0)',
      }, {
        label: 'ANTICIPADO',
        data: labels.map((datosMes) => VectorFinal.filter((dato) => {
          return dato.estado.includes("ANTICIPADO") === true
        }).length),
        backgroundColor: 'rgb(2 34 0)',
      },
    ],
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
  };


  React.useEffect(() => {
    setVectorFinal([])
    if (VectorV.length > 0) {
      setVectorFinal([])
    }
  }, [VectorV])





  return (
    <Container className="ContenedorTabla" style={{ backgroundColor: "" }} maxWidth={"100%"}>
      
      <Container style={{ backgroundColor: "", marginLeft: "0px", width: "100%", maxWidth: "100%" }} className="ContenedorBuscadorTabla">
        <Paper component="form" className='BuscadorTabla' elevation={20}
          style={{ textAlign: "center" }}
          sx={{
            p: '2px 4px', display: 'flex', alignItems: 'center',
          }}>
          <InputBase style={{ backgroundColor: "" }} value={orden} onInputCapture={(event, newInputValue) => {
            setOrden(event.target.value)
          }}
            sx={{ ml: 1, flex: 1 }}
            placeholder="Buscar"
            inputProps={{ 'aria-label': 'Buscar' }} />

          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />

          <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions" onClick={() => {
            setCarga(2)
            ordenesProveedor(new Date(fechainicial.$d).toISOString().split('T')[0], new Date(fechafinal.$d).toISOString().split('T')[0], age, sede).then((result) => {
              setVectorFinal(result)
              setCarga(1)
            });
          }}>

            <PersonSearchIcon />
          </IconButton>



          <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions" onClick={() => {
            const worksheet = XLSX.utils.json_to_sheet(VectorFinal);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');
            XLSX.writeFile(workbook, 'data.xlsx');
          }}>

            <SystemUpdateAltIcon />
          </IconButton>
          <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions" onClick={() => {
            setFiltro(!filtro)
          }}>
            <FilterListIcon />
          </IconButton>
        </Paper>

        <Paper component="form" className={filtro === true ? 'BuscadorTabla' : "BuscadorTablaOculto"} elevation={20}
          style={{ textAlign: "center" }}
          sx={{
            p: '2px 4px', display: 'flex', margin: "5px", alignItems: 'center',
          }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker', 'DatePicker']}>
              <DatePicker label="Fecha inicial" value={fechainicial}
                onChange={(newValue) => { setfechainicial(newValue); }} />
              <DatePicker
                label="Fecha final"
                value={fechafinal}
                onChange={(newValue) => { setfechafinal(newValue); }}
              />
            </DemoContainer>
          </LocalizationProvider>
        </Paper>
        <Paper component="form" className={filtro === true ? 'BuscadorTabla' : "BuscadorTablaOculto"} elevation={20}
          style={{ textAlign: "center" }}
          sx={{
            p: '2px 4px 4px 4px', margin: "5px", display: 'flex', alignItems: 'center',
            borderBottom: "0.2em solid #c82339"
          }}>

          <Select style={{ width: "45%" }}
            labelId="demo-simple-select-autowidth-label"
            id="demo-simple-select-autowidth"
            value={age}
            onChange={(e) => {
              setAge(e.target.value);
            }}
            label="En preparación">
            <MenuItem value={"and CITM_LFCYCLE_ST ne '8' "}>Todos los estados </MenuItem>
            <MenuItem value={" and CITM_LFCYCLE_ST eq '10' "}>Factura Contabilizada</MenuItem>
            <MenuItem value={" and CITM_LFCYCLE_ST eq '6' "}>Pendiente de entrega</MenuItem>
            <MenuItem value={" and CITM_LFCYCLE_ST eq '8' "}>Cancelado</MenuItem>
            <MenuItem value={" and CITM_LFCYCLE_ST eq '9' "}>Entregado</MenuItem>
          </Select>

          <Select style={{ width: "45%" }}
            labelId="demo-simple-select-autowidth-label"
            id="demo-simple-select-autowidth"
            value={sede}
            onChange={(e) => {
              setSede(e.target.value);
            }}
            label="En preparación">
            <MenuItem value={' '}>Todas las Sedes </MenuItem>
            <MenuItem value={"and ((CRECEIVING_SITE eq 'CC1101')  or  (CRECEIVING_SITE eq 'CC1103'))"}>Itagui </MenuItem>
            <MenuItem value={"and ((CRECEIVING_SITE eq 'CC1201')  or  (CRECEIVING_SITE eq 'CC1203'))"}>Bogotá</MenuItem>
            <MenuItem value={"and ((CRECEIVING_SITE eq 'CC1301')  or  (CRECEIVING_SITE eq 'CC1303'))"}>Cali</MenuItem>
            <MenuItem value={"and ((CRECEIVING_SITE eq 'CC1401')  or  (CRECEIVING_SITE eq 'CC1403'))"}>Barranquilla</MenuItem>
            <MenuItem value={"and ((CRECEIVING_SITE eq 'CC1501')  or  (CRECEIVING_SITE eq 'CC1503'))"}>Bucaramanga</MenuItem>
                      <MenuItem value={"and ((CRECEIVING_SITE eq 'CC1601')  or  (CRECEIVING_SITE eq 'CC1603'))"}>
                      </MenuItem>
            <MenuItem value={"and ((CRECEIVING_SITE eq 'CC1701')  or  (CRECEIVING_SITE eq 'CC1703'))"}>Cúcuta</MenuItem>
          </Select>
        </Paper>
      </Container>

      


      <Card sx={{ minWidth: 275 }} elevation={20}>
        <CardContent>
          <Typography sx={{ fontSize: 16 }} color="text.primary" gutterBottom>
            Calificación del proveedor
          </Typography>
          <Typography variant="h5" component="div">
            {(((VectorFinal.filter((dato) => { return dato.dias >= 1 }).length * 100) / VectorFinal.length) - 100) * -1 <= 80 ?
              <Alert severity="error">NO FAVORABLE</Alert> :
              ((((VectorFinal.filter((dato) => { return dato.dias >= 1 }).length * 100) / VectorFinal.length) - 100) * -1) >= 80.1 &&
                (((VectorFinal.filter((dato) => { return dato.dias >= 1 }).length * 100) / VectorFinal.length) - 100) * -1 <= 95 ?
                <Alert severity="warning">FAVORABLE CONDICIONAL</Alert> :
                <Alert severity="success">FAVORABLE</Alert>}
          </Typography>
        </CardContent>
      </Card>

      

      <br />
      <p>Total de registros:{VectorFinal.length}  </p>
      
      <Paper elevation={20}>
        <TableContainer className='TablaContenedor' component={Paper} style={{ width: "100%", backgroundColor: "", borderRadius: "10px" }}>
          <Table >
            <TableHead>
              <TableRow>
                <StyledTableCell>Fecha generación</StyledTableCell>
                <StyledTableCell align="centerr">Compromiso de entrega</StyledTableCell>
                <StyledTableCell align="center">Fecha Real</StyledTableCell>
                <StyledTableCell align="center">Evaluación</StyledTableCell>
                <StyledTableCell align="center">Estado Articulo</StyledTableCell>
                <StyledTableCell align="center">Estado Pedido</StyledTableCell>
                <StyledTableCell align="center">Part Num</StyledTableCell>
                <StyledTableCell align="center"># OC</StyledTableCell>
                <StyledTableCell align="center">Posición pedido</StyledTableCell>
                <StyledTableCell align="center">SKU</StyledTableCell>
                <StyledTableCell align="center">Descripción SKU</StyledTableCell>
                <StyledTableCell align="center">Unidad</StyledTableCell>
                <StyledTableCell align="center">Lugar entrega</StyledTableCell>
                <StyledTableCell align="center">Cantidad Pedida</StyledTableCell>
                <StyledTableCell align="center">Valor Unitario</StyledTableCell>
                <StyledTableCell align="center">Valor Total</StyledTableCell>
                <StyledTableCell align="center">Moneda</StyledTableCell>
                <StyledTableCell align="center">Cantidad Entrega</StyledTableCell>
                <StyledTableCell align="center">Cantidad Pendiente</StyledTableCell>
                <StyledTableCell align="center">Cantidad Facturada</StyledTableCell>
                <StyledTableCell align="center">Valor Facturado</StyledTableCell>
                <StyledTableCell align="center">Categoria</StyledTableCell>
                <StyledTableCell align="center">Proveedor</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {carga === 2 ?
                <Box sx={{ width: '1900%' }}>
                  <LinearProgress />
                </Box> : VectorFinal.filter((dato) => {
                  if (orden.length <= -1) { return dato.IdPedido.includes(orden) === false }
                  return dato.IdPedido.includes(orden) === true
                }).filter((dato) => {
                  if (orden.length <= -1) { return dato.ProductoId.includes(orden) === false }
                  return dato.ProductoId.includes(orden) === true
                }).slice(rowsPerPage * (page + 1) - rowsPerPage, rowsPerPage > 5 ? rowsPerPage : rowsPerPage * page + 5).map((row) => (
                  <StyledTableRow key={Math.random()}>
                    <StyledTableCell align="left" width={100}>{new Date(row.FechaCreacion).toLocaleDateString('es-ES', opciones)}</StyledTableCell>
                    <StyledTableCell align="left">{new Date(row.FechaEntrega).toLocaleDateString('es-ES', opciones)}</StyledTableCell>
                    <StyledTableCell align="left"> {new Date(row.FechaReal).toLocaleDateString('es-ES', opciones)}</StyledTableCell>
                    <StyledTableCell align="left">{row.estado}</StyledTableCell>
                    <StyledTableCell align="left">{row.EstadoArticulo}</StyledTableCell>
                    <StyledTableCell align="left">{row.EstadoPedido}</StyledTableCell>
                    <StyledTableCell align="left">{row.NumeroPiezaProveedor}</StyledTableCell>
                    <StyledTableCell align="left">{row.IdPedido}</StyledTableCell>
                    <StyledTableCell align="left">{row.PosicionPedido}</StyledTableCell>
                    <StyledTableCell align="left">{row.ProductoId}</StyledTableCell>
                    <StyledTableCell align="left">{row.DescProducto}</StyledTableCell>
                    <StyledTableCell align="left">{row.Unidad}</StyledTableCell>
                    <StyledTableCell align="left">{row.Sede}</StyledTableCell>
                    <StyledTableCell align="left">{parseFloat(row.CantidadPedida).toFixed(2)}</StyledTableCell>
                    <StyledTableCell align="left">{Math.round(row.PrecioNeto).toLocaleString('es-CO', { style: 'currency', currency: 'COP' })}</StyledTableCell>
                    <StyledTableCell align="left">{Math.round(row.ValorNeto).toLocaleString('es-CO', { style: 'currency', currency: 'COP' })} </StyledTableCell>
                    <StyledTableCell align="left">{row.moneda}</StyledTableCell>
                    <StyledTableCell align="left">{parseFloat(row.CantidadEntrega).toFixed(2)}  </StyledTableCell>
                    <StyledTableCell align="left">{parseFloat(row.CantidadPedida).toFixed(2)} </StyledTableCell>
                    <StyledTableCell align="left">{parseFloat(row.CantidadFacturada).toFixed(2)}  </StyledTableCell>
                    <StyledTableCell align="left">{Math.round(row.ValorFacturado).toLocaleString('es-CO', { style: 'currency', currency: 'COP' })} </StyledTableCell>
                    <StyledTableCell align="left">{row.Categoria}</StyledTableCell>
                    <StyledTableCell align="left">{row.Proveedor}</StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20, 30, 40, 100, VectorFinal.length]}
          component="div"
          count={VectorFinal.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage} />
      </Paper>


      <Container style={{ padding: "50px", }}>
        <Bar options={options} data={data} />
      </Container>
    </Container>

  );

}