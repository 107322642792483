import React from 'react';
import ReactWhatsappButton from "react-whatsapp-button";
import '../css/login.css';

export default function whatsapp() {
    return (
        <div className="App" >
          <ReactWhatsappButton 
            countryCode="57"
            phoneNumber="3114292085"
            animated
          />
        </div>
      )
}
