import * as React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { Container, Button } from '@mui/material';
import '../css/menu.css';
import AppBard from '../Componentes/appbar'


export default function Menuscitalsa({ componet }) {
  const [spacing, setSpacing] = React.useState(2);
  const [isActive, setIsActive] = React.useState(true);


  return (
    <Container style={{ backgroundColor: "", width: "100%", maxWidth: "100%" }}>
      <Grid sx={{ flexGrow: 1 }} container spacing={2}>
        <Grid item xs={12}>
          <AppBard></AppBard>
          <Grid container justifyContent="center" spacing={spacing} >
            <Container className={isActive ? 'Menu' : 'MenuBorroso'} style={{ backgroundColor: "", width: "100%", maxWidth: "100%", paddingLeft: "0px", paddingRight: "0px" }}>
              {componet}
            </Container>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}