import * as React from 'react';
import { Container } from '@mui/system';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import LinearProgress from '@mui/material/LinearProgress';
import Box from "@mui/material/Box";
import { facturasget } from '../Componentes/FunctionLogin.js'
import { myLocaleText } from '../Componentes/FunctionLogin.js'
import '../css/menu.css'
import dayjs from 'dayjs';
import { Card } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import BasicModalDialogFacturas from '../Componentes/BasicModalDialogFacturas.js';
import {useTranslation} from 'react-i18next'

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { t } from 'i18next';
// mock

// ----------------------------------------------------------------------
const XLSX = require('xlsx');

export default function Proyectlistv2() {
    const [t, i18n] = useTranslation("global");
    let date = new Date();
    date.setMonth(date.getMonth() - 1);
    const [facturaid, setFactura] = React.useState("");
    const [VectorFinal, setVectorFinal] = React.useState([]);
    const [VectorV, setVectorV] = React.useState([]);
    const [carga, setCarga] = React.useState(1);

    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
    );

    React.useEffect(() => {
        setVectorFinal([])
        if (VectorV.length > 0) {
            setVectorFinal([])
        }
    }, [VectorV])


    return (
        <Container className="ContenedorTabla" style={{ backgroundColor: "" }} maxWidth={"100%"}>
            <Container style={{ backgroundColor: "", marginLeft: "0px", width: "100%", maxWidth: "100%" }} className="ContenedorBuscadorTabla">
                <Paper component="form" className='BuscadorTabla' elevation={20}
                    style={{ textAlign: "center" }}
                    sx={{
                        p: '2px 4px', display: 'flex', alignItems: 'center',
                    }}>
                    <InputBase style={{ backgroundColor: "" }} value={facturaid} onInputCapture={(event, newInputValue) => {
                        setFactura(event.target.value)
                    }}
                        sx={{ ml: 1, flex: 1 }}
                        placeholder= {t("facturas.buscar")}
                        inputProps={{ 'aria-label': 'Buscar' }} />

                    <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />

                    <IconButton color="primary" title={t("ordenes.buscar")}  sx={{ p: '10px' }} aria-label="directions" onClick={() => {
                        setCarga(2)

                        facturasget(facturaid).then((result) => {
                            setVectorFinal(result)
                            setCarga(1)
                        });
                    }}>

<                    PersonSearchIcon />  
                    </IconButton>
                    <IconButton color="primary" sx={{ p: '10px' }}  title={t("ordenes.export")}  aria-label="directions" onClick={() => {
                        const worksheet = XLSX.utils.json_to_sheet(VectorFinal);
                        const workbook = XLSX.utils.book_new();
                        XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');
                        XLSX.writeFile(workbook, 'data.xlsx');
                    }}>

                        <SystemUpdateAltIcon />
                    </IconButton>
                </Paper>
            </Container>
            <Card sx={{ minWidth: 275 }} elevation={20}>
                <CardContent>
                    <Typography sx={{ fontSize: 16 }} color="text.primary" gutterBottom>
                        {t("facturas.relacion")}
                    </Typography>
                </CardContent>
            </Card>

            <br />
            <p><b>{t("facturas.importe")}: </b>  {VectorFinal.reduce((a, b) => a + Number(b.importePendiente * -1), 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} </p>
            <Paper elevation={20}>
                {carga === 2 ?
                    <Box sx={{ width: '100%' }}>
                        <LinearProgress />
                    </Box> :
                    <p>{t("facturas.informacion")}</p>
                }
                <div style={{ height: 400, width: '100%' }}>
                    <style>{`
                            .MuiDataGrid-columnHeaderTitle {
                            font-weight: bold;
                            }
                        `}</style>
                    <DataGrid localeText={myLocaleText}
                        rows={VectorFinal}
                        columns={[
                            { field: 'fechaDocumento', headerName: `${t("facturas.FechaDocumento")}`, type: '', width: 200, description:`${t("facturas.FechaDocumento")}`, valueFormatter: (params) => dayjs(params.value).add(1, 'day').format('DD/MM/YYYY'), },
                            { field: 'fechaVencimiento', headerName: `${t("facturas.FechaVencimiento")}`, type: '', width: 200, description: `${t("facturas.FechaVencimiento")}`, valueFormatter: (params) => dayjs(params.value).add(1, 'day').format('DD/MM/YYYY'), },
                            { field: 'idFactura', headerName: `${t("facturas.Factura")}`, type: '', width: 200, description: `${t("facturas.Factura")}` },
                            { field: 'tipodedocumento', headerName: `${t("facturas.TipoDocumento")}`, type: '', width: 200, description: `${t("facturas.TipoDocumento")}` },
                            { field: 'condicion', headerName: `${t("facturas.CondicionPago")}`, type: '', width: 200, description:  `${t("facturas.CondicionPago")}` },
                            { field: 'estado', headerName: `${t("facturas.EstadoPago")}`, type: '', width: 200, description: 'Estado de pago' },
                            { field: 'importePendiente', headerName: `${t("facturas.ValorFactura")}`, type: 'number', width: 200, description: `${t("facturas.ValorFactura")}`, valueFormatter: ({ value }) => (Number(value * -1).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })) },
                            { field: 'moneda', headerName: `${t("facturas.Moneda")}`, type: '', width: 200, description: `${t("facturas.Moneda")}` },
                            {
                                field: 'Contáctanos',
                                headerName:`${t("facturas.Contactanos")}`, width: 200,
                                renderCell: (params) => (
                                    <BasicModalDialogFacturas
                                        factura={params.row.idFactura}
                                        proveedor={localStorage.Tproveedor}
                                        text={""}
                                        boton={`${t("facturas.Contactanos")}`}
                                        tipo={"Facturas"}
                                    ></BasicModalDialogFacturas>
                                ),
                            }
                        ]}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                    />
                </div>
            </Paper>
        </Container>

    );

}