import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, TextField, Button, Grid, Container } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { VisitasProveedor, VisitasProveedorObservaciones, updateObservacion, EnviarCorreoNotificacion } from '../FunctionLogin'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import logo from '../../recursos/logopng.png'
import Checkbox from '@mui/material/Checkbox';
import Archivo from '../Evalucion/archivoEvaluacion';
import { useTheme } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import swal from 'sweetalert';
import {useTranslation} from 'react-i18next'


export default function ConsultaEvaluacion() {
    const [t, i18n] = useTranslation("global");
    const theme = useTheme();
    const [preguntas, setPreguntas] = useState([]);
    const [Menu, setMenu] = useState([]);
    const [causas, setCausas] = useState({});
    const [planes, setPlanes] = useState({});
    const [recarga, setRecarga] = useState();
    const [checked, setChecked] = useState();


    React.useEffect(() => {
        let vectorMenu = [];
        let preguntasArray = []; 
        let cont = 0 ; 
        VisitasProveedor().then((result) => {
            result.forEach(element => {
                cont = cont + 1;
                vectorMenu.push({ id: element[14].Value, fecha: element[11].Value })
                setMenu(vectorMenu)
                VisitasProveedorObservaciones(element[14].Value).then((resultObservaciones) => {
                    resultObservaciones.forEach(element => {
                         preguntasArray.push(element); 
                    });
                    if(result.length == cont){
                        setPreguntas([...preguntasArray]);
                        setRecarga(Math.random.toString())
                    }
                })
            })
        })
    }, [])

    React.useEffect(() => {
    }, [preguntas])





    return (
        <Container maxWidth="lg">
          <Grid container spacing={2}>
            {Menu.map((menu) => (
              <Grid item xs={12} key={menu.id}>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Card sx={{ maxWidth: '100%' }} style={{ width: "100%" }}>
                      <Card sx={{ display: 'flex' }}>
                        <CardMedia
                          component="img"
                          sx={{ width: 151 }}
                          image={logo}
                          alt="Live from space album cover"
                        />
                        <Box sx={{ display: 'flex', flexDirection: 'column', paddingTop: "40px" }}>
                          <CardContent sx={{ flex: '1 0 auto' }}>
                            <Typography component="div" variant="h5">
                              {t("evaluaciones.plan")} {menu.id}
                            </Typography>
                          </CardContent>
                          <Button
                            style={{ width: "40%", backgroundColor: "rgb(191, 38, 66)" }}
                            variant="contained"
                            onClick={() => {
                              EnviarCorreoNotificacion("cpenuela@citalsa.com", localStorage.Tproveedor, menu.id, preguntas.filter(item => item.idradicado === menu.id)).then((result) => {
                                swal(`Se notifico a CI TALSA con exito`, "GRACIAS!", "success");
                              });
                            }}
                            fullWidth>
                            {t("evaluaciones.notificacion")}
                          </Button>
                        </Box>
                      </Card>
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          {t("evaluaciones.diligencia")}: {menu.id}
                        </Typography>
                      </CardContent>
                    </Card>
                  </AccordionSummary>
                  <AccordionDetails>
                    {preguntas.filter(item => item.idradicado === menu.id).map((pregunta) => (
                      <Grid container spacing={2} key={pregunta.id}>
                        <Grid item xs={12} sm={6} md={3}>
                          <TextField
                            label={t("evaluaciones.comentario")}
                            multiline
                            disabled
                            value={pregunta.comentario}
                            rows={2}
                            variant="outlined"
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <TextField
                            label={t("evaluaciones.observaciones")}
                            multiline
                            disabled
                            value={pregunta.observacion}
                            rows={2}
                            variant="outlined"
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <TextField
                            disabled={pregunta.estadoCheck === "True" ? true : false}
                            label={t("evaluaciones.causa")}
                            multiline
                            rows={2}
                            variant="outlined"
                            fullWidth
                            value={causas[pregunta.radicado] || pregunta.causa}
                            onChange={(e) => {
                              const causa = e.target.value;
                              setCausas((prevState) => ({
                                ...prevState,
                                [pregunta.radicado]: causa
                              }));
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <TextField
                            disabled={pregunta.estadoCheck === "True" ? true : false}
                            label={t("evaluaciones.plandeaccion")}
                            multiline
                            rows={2}
                            variant="outlined"
                            fullWidth
                            value={planes[pregunta.radicado] || pregunta.plan}
                            onChange={(e) => {
                              const plan = e.target.value;
                              setPlanes((prevState) => ({
                                ...prevState,
                                [pregunta.radicado]: plan
                              }));
                            }}
                          />
                        </Grid>


                          

                        <Grid item xs={12} sm={6} md={3}>
                         <Typography color={"red"}>
                           {"Fecha de vencimiento: " +pregunta.fechavencimiento.toString().replace("00:00:00","")}
                         </Typography>
                          
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <FormControlLabel
                            style={{ color: "black" }}
                            control={
                              <Checkbox
                                disabled
                                checked={pregunta.estadoCheck === "True" ? true : false}
                                inputProps={{ 'aria-label': 'controlled' }}
                                defaultChecked color="success"
                              />
                            }
                            label={pregunta.estadoCheck === "True" ? `${t("evaluaciones.planaceptado")}` : `${t("evaluaciones.planenrevision")}`}
                          />
                        </Grid>
                        <Grid item xs={6} md={3}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              const causa = causas[pregunta.radicado] || pregunta.causa;
                              const plan = planes[pregunta.radicado] || pregunta.plan;
                              updateObservacion(causa, plan, pregunta.radicado).then(() => {
                                setRecarga(toString(Math.random()));
                              });
                            }}
                            fullWidth
                          >
                            {t("evaluaciones.guardar")}
                          </Button>
                        </Grid>
                        <Grid item xs={6} md={3}>
                          {/* Replace 'Archivo' with your component implementation */}
                          {/* <Archivo idarchivo={pregunta.radicado} boton={"Subir Documento"} /> */}
                        </Grid>
                      </Grid>
                    ))}
                  </AccordionDetails>
                </Accordion>
              </Grid>
            ))}
          </Grid>
        </Container>
      );
}
