import React, { useState } from 'react';
import { Container } from '@mui/material';

export default function VisualizarPDF() {


  return (
    <Container maxWidth={"20px"}>
     
    </Container>
  )
}