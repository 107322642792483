import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, TextField, Button, Grid, Container } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { VisitasProveedorAll, VisitasProveedorObservaciones, updateObservacion, EnviarCorreoNotificacion } from '../FunctionLogin'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import logo from '../../recursos/logopng.png'
import Checkbox from '@mui/material/Checkbox';
import Archivo from '../Evalucion/archivoEvaluacion';
import { useTheme } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import swal from 'sweetalert';


export default function ConsultaEvaluacionAll() {
    const theme = useTheme();
    const [preguntas, setPreguntas] = useState([]);
    const [Menu, setMenu] = useState([]);
    const [causas, setCausas] = useState({});
    const [planes, setPlanes] = useState({});
    const [recarga, setRecarga] = useState();
    const [checked, setChecked] = useState();
    const [radicado, setRadicado] = useState("");
    const [proveedor, setProveedor] = useState("");
 


    React.useEffect(() => {
        let vectorMenu = [];
        let preguntasArray = [];
        let cont = 0;
        VisitasProveedorAll().then((result) => {
            result.forEach(element => {
                cont = cont + 1;
                vectorMenu.push({ id: element[14].Value, fecha: element[11].Value, tProveedor: element[9].Value })
                setMenu(vectorMenu)
                VisitasProveedorObservaciones(element[14].Value).then((resultObservaciones) => {
                    resultObservaciones.forEach(element => {
                        preguntasArray.push(element);
                    });
                    if (result.length == cont) {
                        setPreguntas([...preguntasArray]);
                        setRecarga(Math.random.toString())
                    }
                })
            })
        })
    }, [])

    React.useEffect(() => {
    }, [preguntas])


    return (
        <Container fixed>
            <Grid container spacing={2} alignItems="center" >
                <Grid item xs={12} sm={6} md={3}>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Typography>RADICADO</Typography>
                    <TextField
                        multiline
                        value={radicado}
                        onChange={(e) => {
                            const radicado = e.target.value;
                            setRadicado(radicado);
                        }}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Typography>PROVEEDOR</Typography>
                    <TextField
                        multiline
                        value={proveedor}
                        onChange={(e) => {
                            const proveedor = e.target.value;
                            setProveedor(proveedor);
                        }}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
            </Grid>


            <Container fixed>
                {Menu.filter(item => item.tProveedor.includes(proveedor)).filter(item => item.id.includes(radicado)).map((menu) => (
                    <Container maxWidth="lg">
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                <Card sx={{ maxWidth: '100%' }} style={{ width: "100%" }} >
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="div">
                                            Evaluación: {menu.id}-{menu.tProveedor}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </AccordionSummary>
                            <AccordionDetails>
                                {preguntas.filter(item => item.idradicado == menu.id).map((pregunta) =>
                                 (

                                    <Accordion key={pregunta.id}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                            <h3>{pregunta.pregunta} - {pregunta.radicado} - {pregunta.tProveedor}</h3>
                                        </AccordionSummary>
                                        <AccordionDetails >
                                            <Grid container spacing={2} alignItems="center" color={"red"}>
                                                <Grid item xs={12} sm={6} md={3}>
                                                    <TextField
                                                        label="Comentarios"
                                                        multiline
                                                        disabled
                                                        value={pregunta.comentario}
                                                        rows={2}
                                                        variant="outlined"
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={3}>
                                                    <TextField
                                                        label="Observaciones"
                                                        multiline
                                                        disabled
                                                        value={pregunta.observacion}
                                                        rows={2}
                                                        variant="outlined"
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={3}>
                                                    <TextField
                                                        disabled={pregunta.estadoCheck == "True" ? true : false}
                                                        label="Causa raíz"
                                                        multiline
                                                        rows={2}
                                                        variant="outlined"
                                                        fullWidth
                                                        value={causas[pregunta.radicado] || pregunta.causa}
                                                        onChange={(e) => {
                                                            const causa = e.target.value;
                                                            setCausas((prevState) => ({
                                                                ...prevState,
                                                                [pregunta.radicado]: causa
                                                            }));
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={3}>
                                                    <TextField
                                                        disabled={pregunta.estadoCheck == "True" ? true : false}
                                                        label="Plan de acción"
                                                        multiline
                                                        rows={2}
                                                        variant="outlined"
                                                        fullWidth
                                                        value={planes[pregunta.radicado] || pregunta.plan}
                                                        onChange={(e) => {
                                                            const plan = e.target.value;
                                                            setPlanes((prevState) => ({
                                                                ...prevState,
                                                                [pregunta.radicado]: plan
                                                            }));
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={3}>
                                                    <FormControlLabel style={{ color: "black" }} control={<Checkbox disabled
                                                        checked={(pregunta.estadoCheck == "True" ? true : false)}
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                        defaultChecked color="success" />} label={pregunta.estadoCheck == "True" ? "Plan de acción aceptado" : "Plan de acción en revisión"} />
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                ))}
                            </AccordionDetails>
                        </Accordion>
                    </Container>
                ))
                }
            </Container>
        </Container>
    );
}
