import React, { useState } from 'react';
import { Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Card, Text } from "@nextui-org/react";
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import evaluacion from '../../recursos/evaluacion.png'
import visita from '../../recursos/visita.png'

import Cambioproveedor from '../cambioproveedor.js'
import '../../css/carpetasyear.css';
import { useTranslation } from 'react-i18next'

export default function Evaluation() {
  const navigate = useNavigate();
  const [t, i18next] = useTranslation("global");

  return (
    <Container maxWidth={"20px"}>
      <Container className='' maxWidth={"10px"} style={{ display: "flex", flexWrap: "wrap" }}>
        <Container className='hijosfooterv2' style={{ width: "14.3%", margin: "10px" }} key={Math.floor(Math.random() * 9999999) + 1}>
          <Card isPressable
            isHoverable
            variant="bordered" sx={{ maxWidth: 345 }}>
            <CardMedia
              style={{ objectFit: "contain", cursor: "pointer" }}
              component="img"
              height="150"
              image={visita}
              alt="archivo"
              onClick={() => {
                navigate("/proveedores/visitaproveedores")
              }}
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
              Visita a proveedor Español
              </Typography>
            </CardContent>
          </Card>
        </Container>
        <Container className='hijosfooterv2' style={{ width: "14.3%", margin: "10px" }} key={Math.floor(Math.random() * 9999999) + 1}>
          <Card isPressable
            isHoverable
            variant="bordered" sx={{ maxWidth: 345 }}>
            <CardMedia
              style={{ objectFit: "contain", cursor: "pointer" }}
              component="img"
              height="150"
              image={visita}
              alt="archivo"
              onClick={() => {
                navigate("/proveedores/visitaproveedoresen")
              }}
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Visita a proveedor Inglés
              </Typography>
            </CardContent>
          </Card>
        </Container>
        <Container className='hijosfooterv2' style={{ width: "14.3%", margin: "10px" }} key={Math.floor(Math.random() * 9999999) + 1}>
          <Card isPressable
            isHoverable
            variant="bordered" sx={{ maxWidth: 345 }}>
            <CardMedia
              style={{ objectFit: "contain", cursor: "pointer" }}
              component="img"
              height="150"
              image={evaluacion}
              alt="archivo"
              onClick={() => {
                navigate("/proveedores/visitacalidad")
              }}
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Evaluación de calidad Español
              </Typography>
            </CardContent>
          </Card>
        </Container>

        <Container className='hijosfooterv2' style={{ width: "14.3%", margin: "10px" }} key={Math.floor(Math.random() * 9999999) + 1}>
          <Card isPressable
            isHoverable
            variant="bordered" sx={{ maxWidth: 345 }}>
            <CardMedia
              style={{ objectFit: "contain", cursor: "pointer" }}
              component="img"
              height="150"
              image={evaluacion}
              alt="archivo"
              onClick={() => {
                navigate("/proveedores/visitacalidaden")
              }}
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Evaluación de calidad Inglés
              </Typography>
            </CardContent>
          </Card>
        </Container>

      </Container>
    </Container>
  )
}