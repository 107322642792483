import * as React from 'react';
import { datosDeArchivoWM } from '../Componentes/FunctionLogin.js'
import Cart from '../Componentes/Carpetas/cartpdf.js'
import { Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import AccountCircle from '@mui/icons-material/AccountCircle';



export default function Documentacion() {

    const [base64, setBase64] = React.useState('');


    const [vector, setVector] = React.useState([]);
    const navigate = useNavigate();
    const urlParams = new URLSearchParams(window.location.search);
    const codigo = urlParams.get('codigo');
    React.useEffect(() => {
        if (codigo === "49" || codigo === "59" || codigo === "69" || codigo === "79" || codigo === "89" || codigo === "99") {
            navigate("/proveedores/documentacion")
        }
        //idx
        datosDeArchivoWM(codigo).then((respuesta) => {
            if (respuesta === null) {
                navigate("/proveedores/documentacion")
            }
            setVector(respuesta)
        })
    }, [])

    const [searchTerm, setSearchTerm] = React.useState('');
    const [cambiodevista, setCambioDeVista] = React.useState(false);


    const handleChange = (event) => {
        setSearchTerm(event.target.value);

    };
    const handleChangecheck = (event) =>{
        setCambioDeVista(event.target.checked);
    }


    return (
        <Container className='' maxWidth={"10px"} style={{ display: "flex", flexWrap: "wrap" }}>

            <TextField
                value={searchTerm}
                onChange={handleChange}
                label='Buscador...'
                style={{ border: "solid", borderRadius: "20px", width: "100%", margin: "20px" }}
                startAdornment={
                    <SearchIcon />
                }
            />

            <FormControlLabel style={{margin:"20px"}} checked={cambiodevista} onChange={handleChangecheck} control={<Switch defaultChecked />} label="Activar vista en formato lista" />
            <Container className='' maxWidth={"10px"} style={{ display: "flex", flexWrap: "wrap" }}>
                {vector.filter(item => item.Description.includes(searchTerm.toUpperCase())).reduce((accumulator, currentObject) => {
                    if (!accumulator.some((existingObject) => {
                        return JSON.stringify(existingObject) === JSON.stringify(currentObject);
                    })) {
                        accumulator.push(currentObject);
                    }
                    return accumulator;
                }, []).sort((a, b) => new Date(b.PublishDate) - new Date(a.PublishDate)).map(item => (
                    <Container className={(cambiodevista?"hijosfooterv3":"hijosfooterv2")} style={{ width: (cambiodevista?"200%":"14.3%"), margin: "10px" }} key={Math.floor(Math.random() * 9999999) + 1}>
                        <Cart Description={item.Description} item={item.Code} PublishDate={item.PublishDate} Ext={item.Ext} FileId={item.FileId} Size={item.Size}  tipovista={cambiodevista}/>
                    </Container>
                ))}
            </Container>
        </Container>



    )
}