import { useNavigate as UseNavigate, History, Link as RouterLink } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import Login from './Componentes/Login.js';
import Router from './routes';
import React, { useState, useEffect } from 'react';
import swal from 'sweetalert';

function App() {
/*
  useEffect(() => {
   const handleKeyDown = (event) => {
      if (event.keyCode === 123) {
        event.preventDefault();
        swal("Error!", "Acción no permitida");
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
  useEffect(() => {
    const handleContextMenu = (event) => {
      event.preventDefault(); 
      swal("Error!", "Acción no permitida");
    };
    document.addEventListener('contextmenu', handleContextMenu);
    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);

*/
  return (
    <div className="App">
      <Router />
    </div>
  );
}

export default App;
