import React, { useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import { toString, toUpper } from 'lodash';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import contrasena from '../recursos/contrasena.png';
import CircularProgress from '@mui/material/CircularProgress';
import { TextField, Autocomplete } from '@mui/material';
import { TraerProveedores, Alert, getIDWorkmanager } from '../Componentes/FunctionLogin';
import swal from 'sweetalert';

export default function CambioProveedor() {
    const [nit, setNit] = useState('');
    const [loading, setLoading] = useState(false);
    const [VectorT, setVector] = React.useState([]);
    const [cambiar, setCambiar] = React.useState();

    const handleNitChange = (event) => {
        setNit(event.target.value);
    };


    const handleEncryptClick = () => {
        setLoading(true);
        Alert(nit).then((result) => {
            if (result.length > 0) {
                localStorage.setItem("Proveedor", result[0].Proveedor)
                localStorage.setItem("Tproveedor", result[0].Tproveedor)
                localStorage.setItem("Nit", result[0].Nit)
                localStorage.setItem("TipoProveedor", result[0].tipo)
                getIDWorkmanager(result[0].Proveedor).then((resultv2) => {
                    localStorage.setItem("Categoria", resultv2[8].Value)
                    localStorage.setItem("Criticidad", resultv2[9].Value)
                    resultv2.forEach(element => {
                        if (element.Field == "Radicado") {
                            localStorage.setItem("Radicado", element.Value)
                        }

                    });
                    window.location.reload();
                    setLoading(false);
                })
            }

        });
    };

    React.useEffect(() => {
    }, [VectorT])

    return (
        <>
            <Container maxWidth="sm" style={{ padding: '50px' }}>
                <Card sx={{ maxWidth: 600 }}>
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            Consultar proveedor
                        </Typography>
                        <Box
                            sx={{ display: 'flex', flexDirection: 'column', gap: '16px', maxWidth: '650px' }}>

                            <Autocomplete disablePortal style={{ left: "100px", width: "100%" }}
                                onInputChange={(event, newInputValue) => {
                                    if (newInputValue.length >= 3) {
                                        TraerProveedores(newInputValue).then((result) => {
                                            setVector(result)
                                            setCambiar(Math.random().toString().slice(2, 6))
                                            setNit(newInputValue = newInputValue.substring(0, newInputValue.indexOf("-")))
                                        })
                                    }
                                }}
                                id="controllable-states-demo"
                                options={VectorT.filter(item => !item.Radicado)}
                                label={VectorT.filter(item => !item.Radicado)}
                                sx={{ width: 480 }}
                                renderInput={(params) => <TextField autoComplete='false' {...params} label="Proveedor" />} />
                            <Button variant="contained" onClick={handleEncryptClick} disabled={loading}>
                                {loading ? <CircularProgress size={24} /> : 'Cambiar'}
                            </Button>
                        </Box>
                    </CardContent>
                </Card>
            </Container>
        </>
    );
}
