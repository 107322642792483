
import jsPDF from 'jspdf';
import cabeceraImage from '../recursos/cabecera.png';

import { EnviarCorreopdf } from '../Componentes/FunctionLogin'

export async function generatePDF(date, contactos, tableData, valorCalificacion, firma, firma2, pais, razonSocial, Telefono, direccion, CuantoTiempo, ciudad, promediocompras, select, responsableVisita, responsableRecibir) {
    return new Promise((resolve) => {
        const doc = new jsPDF({
            orientation: 'landscape',
            margin: '10px', 
        });
        doc.addImage(cabeceraImage, 'JPEG', 5, 5, 287, 50); // Ajusta las coordenadas y dimensiones
        // Agrega el contenido línea por línea
        doc.setFont('', 'bold');
        doc.setFontSize(10);
        doc.cell(5, 55, 200, 13, 'VISITA DE SOCIOS COMERCIALES', 0);
        doc.cell(5, 55, 87, 13, 'Código: F-CS-CP-04 \nVersion: 07', 0);

        doc.setFont('', 'bold');
        doc.setFillColor(245, 245, 245); // Sets the background color to light gray (RGB: 245, 245, 245)
        doc.rect(5, 73, 287, 9, 'FD'); // Draws a rectangle with the background color
        doc.setTextColor(5, 0, 0); // Sets the text color to black
        doc.text('INFORMACIÓN GENERAL', 150, 78, 'center');

        doc.setFont('', '');
        //------------------------------------------------------------------------------------------------
        doc.setFillColor(255, 255, 255); // Sets the background color to light gray (RGB: 245, 245, 245)
        doc.rect(5, 80, 80, 7, 'FD'); // Draws a rectangle with the background color for the second text
        doc.setTextColor(5, 0, 0); // Sets the text color to black
        doc.text('FECHA DE VISITA', 6, 84, 'left');
        //-----
        doc.setFillColor(255, 255, 255); // Sets the background color to light gray (RGB: 245, 245, 245)
        doc.rect(80, 80, 80, 7, 'FD'); // Draws a rectangle with the background color for the second text
        doc.setTextColor(5, 0, 0); // Sets the text color to black
        doc.text(date, 81, 84, 'left');
        //-----
        doc.setFillColor(255, 255, 255); // Sets the background color to light gray (RGB: 245, 245, 245)
        doc.rect(160, 80, 80, 7, 'FD'); // Draws a rectangle with the background color for the second text
        doc.setTextColor(5, 0, 0); // Sets the text color to black
        doc.text('PAÍS', 161, 84, 'left');
        //-----
        doc.setFillColor(255, 255, 255); // Sets the background color to light gray (RGB: 245, 245, 245)
        doc.rect(240, 80, 52, 7, 'FD'); // Draws a rectangle with the background color for the second text
        doc.setTextColor(5, 0, 0); // Sets the text color to black
        doc.text(pais, 241, 84, 'left');

        //------------------------------------------------------------------------------------------------
        doc.setFillColor(255, 255, 255); // Sets the background color to light gray (RGB: 245, 245, 245)
        doc.rect(5, 87, 80, 9, 'FD'); // Draws a rectangle with the background color for the second text
        doc.setTextColor(5, 0, 0); // Sets the text color to black
        doc.text('NOMBRE DE LA EMPRESA', 6, 91, 'left');
        //-----


        var razonSocialv2 = doc.splitTextToSize(razonSocial, 80);
        doc.setFillColor(255, 255, 255); // Sets the background color to light gray (RGB: 245, 245, 245)
        doc.rect(80, 87, 80, 9, 'FD'); // Draws a rectangle with the background color for the second text
        doc.setTextColor(5, 0, 0); // Sets the text color to black
        doc.text(razonSocialv2, 81, 91, 'left');
        //-----
    
        doc.setFillColor(255, 255, 255); // Sets the background color to light gray (RGB: 245, 245, 245)
        doc.rect(160, 87, 80, 9, 'FD'); // Draws a rectangle with the background color for the second text
        doc.setTextColor(5, 0, 0); // Sets the text color to black
        doc.text('TELÉFONO', 161, 91, 'left');
        //-----
        doc.setFillColor(255, 255, 255); // Sets the background color to light gray (RGB: 245, 245, 245)
        doc.rect(240, 87, 52, 9, 'FD'); // Draws a rectangle with the background color for the second text
        doc.setTextColor(5, 0, 0); // Sets the text color to black
        doc.text(Telefono, 241, 91, 'left');
        //------------------------------------------------------------------------------------------------

        doc.setFillColor(255, 255, 255); // Sets the background color to light gray (RGB: 245, 245, 245)
        doc.rect(5, 96, 80, 13, 'FD'); // Draws a rectangle with the background color for the second text
        doc.setTextColor(5, 0, 0); // Sets the text color to black
        doc.text('DIRECCIÓN', 6, 101, 'left');
        //-----
        

        var direccionv2 = doc.splitTextToSize(direccion, 80);
        doc.setFillColor(255, 255, 255); // Sets the background color to light gray (RGB: 245, 245, 245)
        doc.rect(80, 96, 80, 13, 'FD'); // Draws a rectangle with the background color for the second text
        doc.setTextColor(5, 0, 0); // Sets the text color to black
        doc.text(direccionv2, 81, 101, 'left');
        //-----

        var cuanto = doc.splitTextToSize('¿CUÁNTO TIEMPO HA ESTADO CONECTADO A CI TALSA?', 80);
        doc.setFillColor(255, 255, 255); // Sets the background color to light gray (RGB: 245, 245, 245)
        doc.rect(160, 96, 80, 13, 'FD'); // Draws a rectangle with the background color for the second text
        doc.setTextColor(5, 0, 0); // Sets the text color to black
        doc.text(cuanto, 161, 101, 'left');
        //-----
        doc.setFillColor(255, 255, 255); // Sets the background color to light gray (RGB: 245, 245, 245)
        doc.rect(240, 96, 52, 13, 'FD'); // Draws a rectangle with the background color for the second text
        doc.setTextColor(5, 0, 0); // Sets the text color to black
        doc.text(`${CuantoTiempo}`, 241, 101, 'left');
        //------------------------------------------------------------------------------------------------

        doc.setFillColor(255, 255, 255); // Sets the background color to light gray (RGB: 245, 245, 245)
        doc.rect(5, 109, 80, 13, 'FD'); // Draws a rectangle with the background color for the second text
        doc.setTextColor(5, 0, 0); // Sets the text color to black
        doc.text('CIUDAD', 6, 114, 'left');
        //-----
        doc.setFillColor(255, 255, 255); // Sets the background color to light gray (RGB: 245, 245, 245)
        doc.rect(80, 109, 80, 13, 'FD'); // Draws a rectangle with the background color for the second text
        doc.setTextColor(5, 0, 0); // Sets the text color to black
        doc.text(ciudad, 81, 114, 'left');
        //-----
        doc.setFillColor(255, 255, 255); // Sets the background color to light gray (RGB: 245, 245, 245)
        doc.rect(160, 109, 80, 13, 'FD'); // Draws a rectangle with the background color for the second text
        doc.setTextColor(5, 0, 0); // Sets the text color to black
        doc.text('¿COMPRAS MEDIAS EN EL ÚLTIMO AÑO?', 161, 114, 'left');
        //-----
        doc.setFillColor(255, 255, 255); // Sets the background color to light gray (RGB: 245, 245, 245)
        doc.rect(240, 109, 52, 13, 'FD'); // Draws a rectangle with the background color for the second text
        doc.setTextColor(5, 0, 0); // Sets the text color to black
        doc.text(`${promediocompras}`, 241, 114, 'left');
        //------------------------------------------------------------------------------------------------
        doc.setFont('', 'bold');
        doc.setFillColor(245, 245, 245); // Sets the background color to light gray (RGB: 245, 245, 245)
        doc.rect(5, 122, 287, 9, 'FD'); // Draws a rectangle with the background color
        doc.setTextColor(5, 0, 0); // Sets the text color to black
        doc.text(`PRODUCTOS O SERVICIOS PRESTADOS A LA EMPRESA: ${select}`, 6, 127, 'left');


        //------------------------------------------------------------------------------------------------
        doc.setFont('', 'bold');
        doc.setFillColor(245, 245, 245); // Sets the background color to light gray (RGB: 245, 245, 245)
        doc.rect(5, 133, 75, 7, 'FD'); // Draws a rectangle with the background color for the second text
        doc.setTextColor(5, 0, 0); // Sets the text color to black
        doc.text('CONTACTO', 6, 138, 'left');
        //-----
        doc.setFillColor(245, 245, 245); // Sets the background color to light gray (RGB: 245, 245, 245)
        doc.rect(75, 133, 75, 7, 'FD'); // Draws a rectangle with the background color for the second text
        doc.setTextColor(5, 0, 0); // Sets the text color to black
        doc.text('NOMBRE', 76, 138, 'left');
        //-----
        doc.setFillColor(245, 245, 245); // Sets the background color to light gray (RGB: 245, 245, 245)
        doc.rect(145, 133, 70, 7, 'FD'); // Draws a rectangle with the background color for the second text
        doc.setTextColor(5, 0, 0); // Sets the text color to black
        doc.text('TELÉFONO', 146, 138, 'left');
        //-----  
        doc.setFillColor(245, 245, 245); // Sets the background color to light gray (RGB: 245, 245, 245)
        doc.rect(215, 133, 77, 7, 'FD'); // Draws a rectangle with the background color for the second text
        doc.setTextColor(5, 0, 0); // Sets the text color to black
        doc.text('EMAIL', 216, 138, 'left');
        //------------------------------------------------------------------------------------------------

        var yContactoRetangulo = 140, yTexto = 150;
        contactos.forEach(element => {
            var xContactoRetangulo = 5;
            doc.setFont('', '');
            doc.setFillColor(255, 255, 255); // Establece el color de fondo en gris claro (RGB: 245, 245, 245)
            doc.rect(xContactoRetangulo, yContactoRetangulo, 70, 7, 'FD'); // Dibuja un rectángulo con el color de fondo para el segundo texto
            doc.setTextColor(5, 0, 0); // Establece el color del texto en negro
            doc.text(element.contacto, (xContactoRetangulo + 1), yTexto - 5, 'left');
            xContactoRetangulo = xContactoRetangulo + 70;
            //-----
            doc.setFillColor(255, 255, 255); // Establece el color de fondo en gris claro (RGB: 245, 245, 245)
            doc.rect(xContactoRetangulo, yContactoRetangulo, 70, 7, 'FD'); // Dibuja un rectángulo con el color de fondo para el segundo texto
            doc.setTextColor(5, 0, 0); // Establece el color del texto en negro
            doc.text(element.nombre, (xContactoRetangulo + 1), yTexto - 5, 'left');
            xContactoRetangulo = xContactoRetangulo + 70;
            //-----
            doc.setFillColor(255, 255, 255); // Establece el color de fondo en gris claro (RGB: 245, 245, 245)
            doc.rect(xContactoRetangulo, yContactoRetangulo, 70, 7, 'FD'); // Dibuja un rectángulo con el color de fondo para el segundo texto
            doc.setTextColor(5, 0, 0); // Establece el color del texto en negro
            doc.text(element.telefono, (xContactoRetangulo + 1), yTexto - 5, 'left');
            xContactoRetangulo = xContactoRetangulo + 70;
            //-----  
            doc.setFillColor(255, 255, 255); // Establece el color de fondo en gris claro (RGB: 245, 245, 245)
            doc.rect(xContactoRetangulo, yContactoRetangulo, 77, 7, 'FD'); // Dibuja un rectángulo con el color de fondo para el segundo texto
            doc.setTextColor(5, 0, 0); // Establece el color del texto en negro
            doc.text(element.email, (xContactoRetangulo + 1), yTexto - 5, 'left');
            yContactoRetangulo = yContactoRetangulo + 7;
            yTexto = yTexto + 7;

        });


        if (yContactoRetangulo >= 190) {
            doc.addPage()
            yTexto = 5;
            yContactoRetangulo = 0;
        }


        doc.setFont('', 'bold');
        doc.setFillColor(245, 245, 245);
        doc.rect(35, (yContactoRetangulo + 1), 90, 14, 'FD'); // Draw a rectangle with the background color for the second text
        doc.setTextColor(5, 0, 0); // Set text color to black
        doc.text('SISTEMA DE PUNTUACIÓN', (35 + 1), (yTexto), 'left');

        doc.setFont('', '');
        doc.setFillColor(255, 255, 255);
        doc.rect(90, (yContactoRetangulo + 1), 40, 7, 'FD'); // Draw a rectangle with the background color for the second text
        doc.setTextColor(5, 0, 0); // Set text color to black
        doc.text('1', (90 + 18), (yTexto - 3), 'left');
        doc.setFont('', '');
        doc.setFillColor(255, 255, 255);
        doc.rect(90, (yContactoRetangulo + 1 + 7), 40, 7, 'FD'); // Draw a rectangle with the background color for the second text
        doc.setTextColor(5, 0, 0); // Set text color to black
        doc.text('2', (90 + 18), (yTexto + 3), 'left');

        doc.setFont('', '');
        doc.setFillColor(255, 255, 255);
        doc.rect(130, (yContactoRetangulo + 1), 40, 7, 'FD'); // Draw a rectangle with the background color for the second text
        doc.setTextColor(5, 0, 0); // Set text color to black
        doc.text('No cumple', (130 + 7), (yTexto - 3), 'left');
        doc.setFont('', '');
        doc.setFillColor(255, 255, 255);
        doc.rect(130, (yContactoRetangulo + 1 + 7), 40, 7, 'FD'); // Draw a rectangle with the background color for the second text
        doc.setTextColor(5, 0, 0); // Set text color to black
        doc.text('Cumple parcialmente', (130 + 7), (yTexto + 4), 'left');

        doc.setFont('', '');
        doc.setFillColor(255, 255, 255);
        doc.rect(170, (yContactoRetangulo + 1), 40, 14, 'FD'); // Draw a rectangle with the background color for the second text
        doc.setTextColor(5, 0, 0); // Set text color to black
        doc.text('3', (170 + 18), (yTexto), 'justify');

        doc.setFont('', '');
        doc.setFillColor(255, 255, 255);
        doc.rect(210, (yContactoRetangulo + 1), 40, 14, 'FD'); // Draw a rectangle with the background color for the second text
        doc.setTextColor(5, 0, 0); // Set text color to black
        doc.text('Cumple', (210 + 15), (yTexto), 'justify');

        if (yContactoRetangulo >= 180) {
            doc.addPage()
            yTexto = 5;
            yContactoRetangulo = 0;
        }

        yContactoRetangulo = yContactoRetangulo + 17;
        yTexto = yTexto + 17;

        if (yContactoRetangulo >= 140) {
            doc.addPage()
            yTexto = 17;
            yContactoRetangulo = 10;
        }

        doc.setFont('', 'bold');
        doc.setFillColor(245, 245, 245); // Set background color to light gray (RGB: 245, 245, 245)
        doc.rect(5, yContactoRetangulo, 193, 9, 'FD'); // Draw a rectangle with the background color
        doc.setTextColor(5, 0, 0); // Set text color to black
        doc.text('PARÁMETROS DE CLASIFICACIÓN', 6, yTexto - 2, 'left');
        //---------
        doc.setFont('', 'bold');
        doc.setFillColor(245, 245, 245); // Set background color to light gray (RGB: 245, 245, 245)
        doc.rect(193, yContactoRetangulo, 33.3, 9, 'FD'); // Draw a rectangle with the background color
        doc.setTextColor(5, 0, 0); // Set text color to black
        doc.text('CALIFICACIÓN', 194, yTexto - 2, 'left');
        //--------
        doc.setFont('', 'bold');
        doc.setFillColor(245, 245, 245); // Set background color to light gray (RGB: 245, 245, 245)
        doc.rect(226.3, yContactoRetangulo, 33.3, 9, 'FD'); // Draw a rectangle with the background color
        doc.setTextColor(5, 0, 0); // Set text color to black
        doc.text('COMENTARIO', 227.3, yTexto - 2, 'left');
        //---------
        doc.setFont('', 'bold');
        doc.setFillColor(245, 245, 245); // Set background color to light gray (RGB: 245, 245, 245)
        doc.rect(259.3, yContactoRetangulo, 33.3, 9, 'FD'); // Draw a rectangle with the background color
        doc.setTextColor(5, 0, 0); // Set text color to black
        doc.text('OBSERVACIÓN', 260.3, yTexto - 2, 'left');


        const tHeight = doc.internal.pageSize.getHeight(); // Obtén el ancho de la página
        

        var textoTablaPreguntas = yTexto;
        var RetanguloPreguntas = yContactoRetangulo;
  
        tableData.forEach(elementv1 => {
            textoTablaPreguntas = textoTablaPreguntas + 12;
            RetanguloPreguntas = RetanguloPreguntas + 12;
            doc.setFont('', '');
            doc.setFillColor(245, 245, 245); // Establece el color de fondo en gris claro (RGB: 245, 245, 245)
            doc.rect(5, RetanguloPreguntas, 287.5, 9, 'FD');
            doc.setTextColor(5, 0, 0); // Establece el color del texto en negro
            doc.text(elementv1.category, 6, textoTablaPreguntas-1, 'left');
            textoTablaPreguntas = textoTablaPreguntas + 9;
            RetanguloPreguntas = RetanguloPreguntas + 9;

            elementv1.questions.forEach(element => {
                var alto = 0;
                var comentario = doc.splitTextToSize(element.comment, 40);
                var observation = doc.splitTextToSize(element.observation, 40);
                if ((observation.length >= comentario.length)) {
                    alto = observation.length;
                } else {
                    alto = comentario.length;
                }
                if (RetanguloPreguntas+(observation.length*7) >= 190 || RetanguloPreguntas+(comentario.length*7) >= 190 ) {
                    doc.addPage()
                    textoTablaPreguntas = 10;
                    RetanguloPreguntas = 5;
                }
                doc.setFont('', '');
                doc.setFillColor(255, 255, 255); // Establece el color de fondo en gris claro (RGB: 245, 245, 245)
                doc.rect(5, RetanguloPreguntas, 240, (alto == 1 ? 14 : (alto / 3) * 13), 'FD'); // Dibuja un rectángulo con el color de fondo
                doc.setTextColor(5, 0, 0); // Establece el color del texto en negro
                var textLines = doc.splitTextToSize(element.question, 150);
                doc.text(textLines, 6, textoTablaPreguntas, 'left');



                doc.setFont('', '');
                doc.setFillColor(255, 255, 255); // Establece el color de fondo en gris claro (RGB: 245, 245, 245)
                doc.rect(193, RetanguloPreguntas, 33.3, (alto == 1 ? 14 : (alto / 3) * 13), 'FD'); // Dibuja un rectángulo con el color de fondo
                doc.setTextColor(5, 0, 0); // Establece el color del texto en negro
                doc.text(`${element.evaluation}`, 194, textoTablaPreguntas, 'left');


                doc.setFontSize(8)
                doc.setFont('', '');
                doc.setFillColor(255, 255, 255); // Establece el color de fondo en gris claro (RGB: 245, 245, 245)
                doc.rect(226.3, RetanguloPreguntas, 36.3, (alto == 1 ? 14 : (alto / 3) * 13), 'FD'); // Dibuja un rectángulo con el color de fondo
                doc.setTextColor(5, 0, 0); // Establece el color del texto en negro
                doc.text(comentario, 227.3, textoTablaPreguntas-2, 'left');



                doc.setFontSize(8)
                doc.setFont('', '');
                doc.setFillColor(255, 255, 255); // Establece el color de fondo en gris claro (RGB: 245, 245, 245)
                doc.rect(259.3, RetanguloPreguntas, 33.3, (alto == 1 ? 14 : (alto / 3) * 13), 'FD'); // Dibuja un rectángulo con el color de fondo
                doc.setTextColor(5, 0, 0); // Establece el color del texto en negro
                doc.text(observation, 260.3, textoTablaPreguntas-2, 'left');


                RetanguloPreguntas = RetanguloPreguntas + (alto == 1 ? 14 : (alto / 3) * 13);
                textoTablaPreguntas = textoTablaPreguntas + (alto == 1 ? 14 : (alto / 3) * 13);

                doc.setFontSize(10)
                if (RetanguloPreguntas >= 190) {
                    doc.addPage()
                    textoTablaPreguntas = 10;
                    RetanguloPreguntas = 5;
                }

            })
        });


        doc.setFontSize(10)
        if (RetanguloPreguntas >= 190) {
            doc.addPage()
            textoTablaPreguntas = 5;
            RetanguloPreguntas = 0;
        }
        var tablafinal = [1, 2, 3, 4, 5];

        tablafinal.forEach(element => {
            if (element == 1) {
                doc.setFontSize(10)
                if (RetanguloPreguntas >= 190) {
                    doc.addPage()
                    textoTablaPreguntas = 10;
                    RetanguloPreguntas = 5;
                }

                textoTablaPreguntas = textoTablaPreguntas + 5;
                RetanguloPreguntas = RetanguloPreguntas + 5;
                doc.setFont('', 'bold');
                doc.setFillColor(245, 245, 245); // Set the background color to light gray (RGB: 245, 245, 245)
                doc.rect(60, RetanguloPreguntas, 150, 9, 'FD'); // Draw a rectangle with the background color
                doc.setTextColor(5, 0, 0); // Set the text color to black
                doc.text('Evaluación del Asociado Comercial', 61, textoTablaPreguntas, 'left');
                doc.setFont('', 'bold');
                doc.setFillColor(255, 255, 255); // Set the background color to white (RGB: 255, 255, 255)
                doc.rect(210, RetanguloPreguntas, 70, 9, 'FD'); // Draw a rectangle with the background color
                doc.setTextColor(5, 0, 0); // Set the text color to black
                doc.text(`${valorCalificacion} %`, 212, textoTablaPreguntas, 'left');

            } else if (element == 2) {

                doc.setFontSize(10)
                if (RetanguloPreguntas >= 190) {
                    doc.addPage()
                    textoTablaPreguntas = 10;
                    RetanguloPreguntas = 5;
                }

                textoTablaPreguntas = textoTablaPreguntas + 10;
                RetanguloPreguntas = RetanguloPreguntas + 10;
                doc.setFont('', 'bold');
                doc.setFillColor(245, 245, 245); // Set the background color to light gray (RGB: 245, 245, 245)
                doc.rect(60, RetanguloPreguntas, 150, 9, 'FD'); // Draw a rectangle with the background color
                doc.setTextColor(5, 0, 0); // Set the text color to black
                doc.text('PUNTUACIÓN', 61, textoTablaPreguntas, 'left');
                doc.setFont('', 'bold');
                (valorCalificacion > 80 ? doc.setFillColor(51, 162, 23) : valorCalificacion > 60 && valorCalificacion <= 80 ? doc.setFillColor(0, 232, 255) : doc.setFillColor(255, 0, 0))
                // Set the background color to light gray (RGB: 245, 245, 245)
                doc.rect(210, RetanguloPreguntas, 70, 9, 'FD'); // Draw a rectangle with the background color
                doc.setTextColor(5, 0, 0); // Set the text color to black
                doc.text(`${(valorCalificacion > 80 ? "FAVORABLE" : valorCalificacion > 60 && valorCalificacion <= 80 ? "CONDICIONALMENTE FAVORABLE" : "NOT FAVORABLE")} `, 212, textoTablaPreguntas, 'left');

                if (RetanguloPreguntas >= 180) {
                    doc.addPage()
                    textoTablaPreguntas = 10;
                    RetanguloPreguntas = 5;
                }

            } else if (element == 3) {
                doc.setFontSize(10)
                if (RetanguloPreguntas >= 190) {
                    doc.addPage()
                    textoTablaPreguntas = 10;
                    RetanguloPreguntas = 5;
                }
                textoTablaPreguntas = textoTablaPreguntas + 20;
                RetanguloPreguntas = RetanguloPreguntas + 20;
                doc.setFont('', 'bold');
                doc.setFillColor(245, 245, 245); // Set the background color to light gray (RGB: 245, 245, 245)
                doc.rect(5, RetanguloPreguntas, 70, 9, 'FD'); // Draw a rectangle with the background color
                doc.setTextColor(5, 0, 0); // Set the text color to black
                doc.text('TIPO DE ASOCIACIÓN EMPRESARIAL', 6, textoTablaPreguntas, 'left');
                doc.setFont('', 'bold');
                doc.setFillColor(245, 245, 245); // Set the background color to light gray (RGB: 245, 245, 245)
                doc.rect(70, RetanguloPreguntas, 40, 9, 'FD'); // Draw a rectangle with the background color
                doc.setTextColor(5, 0, 0); // Set the text color to black
                doc.text('PUNTUACIÓN', 76, textoTablaPreguntas, 'left');
                doc.setFont('', 'bold');
                doc.setFillColor(245, 245, 245); // Set the background color to light gray (RGB: 245, 245, 245)
                doc.rect(115, RetanguloPreguntas, 105, 9, 'FD'); // Draw a rectangle with the background color
                doc.setTextColor(5, 0, 0); // Set the text color to black
                doc.text('ACCIÓN A SEGUIR', 116, textoTablaPreguntas, 'left');
                doc.setFont('', 'bold');
                doc.setFillColor(245, 245, 245); // Set the background color to light gray (RGB: 245, 245, 245)
                doc.rect(220, RetanguloPreguntas, 73, 9, 'FD'); // Draw a rectangle with the background color
                doc.setTextColor(5, 0, 0); // Set the text color to black
                doc.text('CONTROL', 221, textoTablaPreguntas, 'left');

                //--------------------------------------------------------------------------------------------------
                doc.setFontSize(10)
                if (RetanguloPreguntas >= 190) {
                    doc.addPage()
                    textoTablaPreguntas = 10;
                    RetanguloPreguntas = 5;
                }
                textoTablaPreguntas = textoTablaPreguntas + 9;
                RetanguloPreguntas = RetanguloPreguntas + 9;
                doc.setFont('', '');
                doc.setFillColor(255, 255, 255); // Set the background color to light gray (RGB: 245, 245, 245)
                doc.rect(5, RetanguloPreguntas, 70, 14, 'FD'); // Draw a rectangle with the background color
                doc.setTextColor(5, 0, 0); // Set the text color to black
                doc.text('FAVORABLE', 6, textoTablaPreguntas, 'left');

                doc.setFont('', '');
                doc.setFillColor(255, 255, 255); // Set the background color to light gray (RGB: 245, 245, 245)
                doc.rect(70, RetanguloPreguntas, 40, 14, 'FD'); // Draw a rectangle with the background color
                doc.setTextColor(5, 0, 0); // Set the text color to black
                doc.text('> 80 ', 76, textoTablaPreguntas, 'left');
                doc.setFont('');

                var texto1 = doc.splitTextToSize("Destaque los puntos fuertes para mantener la posición y los no tan fuertes para mejorar.", 70);
                doc.setFillColor(255, 255, 255); // Set the background color to light gray (RGB: 245, 245, 245)
                doc.rect(115, RetanguloPreguntas, 105, 14, 'FD'); // Draw a rectangle with the background color
                doc.setTextColor(5, 0, 0); // Set the text color to black
                doc.text(texto1, 116, textoTablaPreguntas, 'left');

                var texto2 = doc.splitTextToSize("No se requiere verificación", 70);
                doc.setFont('');
                doc.setFillColor(255, 255, 255); // Set the background color to light gray (RGB: 245, 245, 245)
                doc.rect(220, RetanguloPreguntas, 73, 14, 'FD'); // Draw a rectangle with the background color
                doc.setTextColor(5, 0, 0); // Set the text color to black
                doc.text('No se requiere verificación', 221, textoTablaPreguntas, 'left');


                //--------------------------------------------------------------------------------------------------
                doc.setFontSize(10)
                if (RetanguloPreguntas >= 190) {
                    doc.addPage()
                    textoTablaPreguntas = 10;
                    RetanguloPreguntas = 5;
                }
                textoTablaPreguntas = textoTablaPreguntas + 14;
                RetanguloPreguntas = RetanguloPreguntas + 14;
                doc.setFont('', '');
                doc.setFillColor(255, 255, 255); // Set the background color to light gray (RGB: 245, 245, 245)
                doc.rect(5, RetanguloPreguntas, 70, 14, 'FD'); // Draw a rectangle with the background color
                doc.setTextColor(5, 0, 0); // Set the text color to black
                doc.text('CONDICIONALMENTE FAVORABLE', 6, textoTablaPreguntas, 'left');

                doc.setFont('', '');
                doc.setFillColor(255, 255, 255); // Set the background color to light gray (RGB: 245, 245, 245)
                doc.rect(70, RetanguloPreguntas, 40, 14, 'FD'); // Draw a rectangle with the background color
                doc.setTextColor(5, 0, 0); // Set the text color to black
                doc.text('> 60  AND <= 80', 76, textoTablaPreguntas, 'left');
                doc.setFont('');

                var texto1 = doc.splitTextToSize("Presentar los puntos débiles para que el asociado ponga en marcha acciones.", 70);
                doc.setFillColor(255, 255, 255); // Set the background color to light gray (RGB: 245, 245, 245)
                doc.rect(115, RetanguloPreguntas, 105, 14, 'FD'); // Draw a rectangle with the background color
                doc.setTextColor(5, 0, 0); // Set the text color to black
                doc.text(texto1, 116, textoTablaPreguntas, 'left');

                var texto2 = doc.splitTextToSize("Comprobación final al cabo de tres meses", 70);
                doc.setFont('', '');
                doc.setFillColor(255, 255, 255); // Set the background color to light gray (RGB: 245, 245, 245)
                doc.rect(220, RetanguloPreguntas, 73, 14, 'FD'); // Draw a rectangle with the background color
                doc.setTextColor(5, 0, 0); // Set the text color to black
                doc.text(texto2, 221, textoTablaPreguntas, 'left');

                //--------------------------------------------------------------------------------------------------
                doc.setFontSize(10);
                if (RetanguloPreguntas >= 190) {
                    doc.addPage();
                    textoTablaPreguntas = 10;
                    RetanguloPreguntas = 5;
                }
                textoTablaPreguntas = textoTablaPreguntas + 14;
                RetanguloPreguntas = RetanguloPreguntas + 14;
                doc.setFont('', '');
                doc.setFillColor(255, 255, 255); // Set the background color to light gray (RGB: 245, 245, 245)
                doc.rect(5, RetanguloPreguntas, 70, 14, 'FD'); // Draw a rectangle with the background color
                doc.setTextColor(5, 0, 0); // Set the text color to black
                doc.text('NO FAVORABLE', 6, textoTablaPreguntas, 'left');

                doc.setFont('', '');
                doc.setFillColor(255, 255, 255); // Set the background color to light gray (RGB: 245, 245, 245)
                doc.rect(70, RetanguloPreguntas, 40, 14, 'FD'); // Draw a rectangle with the background color
                doc.setTextColor(5, 0, 0); // Set the text color to black
                doc.text('> 0 AND <= 60', 76, textoTablaPreguntas, 'left');
                doc.setFont('');

                var texto1 = doc.splitTextToSize("Se informa al asociado que no ha cumplido con los parámetros esperados para seguir utilizando sus servicios NO FAVORABLE.", 70);
                doc.setFillColor(255, 255, 255); // Set the background color to light gray (RGB: 245, 245, 245)
                doc.rect(115, RetanguloPreguntas, 105, 14, 'FD'); // Draw a rectangle with the background color
                doc.setTextColor(5, 0, 0); // Set the text color to black
                doc.text(texto1, 116, textoTablaPreguntas, 'left');

                var texto2 = doc.splitTextToSize("Estudiar los planes de acción enviados por el proveedor.", 70);
                doc.setFont('', '');
                doc.setFillColor(255, 255, 255); // Set the background color to light gray (RGB: 245, 245, 245)
                doc.rect(220, RetanguloPreguntas, 73, 14, 'FD'); // Draw a rectangle with the background color
                doc.setTextColor(5, 0, 0); // Set the text color to black
                doc.text(texto2, 221, textoTablaPreguntas, 'left');

            } else if (element == 4) {

                if (RetanguloPreguntas >= 190) {
                    doc.addPage()
                    textoTablaPreguntas = 10;
                    RetanguloPreguntas = 5;
                }

                /* doc.setFont('', 'bold');
                 doc.setFillColor(245, 245, 245); // Establece el color de fondo en gris claro (RGB: 245, 245, 245)
                 doc.rect(5, 91, 300, 9, 'FD'); // Dibuja un rectángulo con el color de fondo
                 doc.setTextColor(5, 0, 0); // Establece el color del texto en negro
                 doc.text(`PRODUCTO O SERVICIOS QUE SUMINISTRA A LA EMPRESA: ${select}`, 6, 98, 'left');*/


                textoTablaPreguntas = textoTablaPreguntas + 25;
                RetanguloPreguntas = RetanguloPreguntas + 25;
                var politicas = doc.splitTextToSize("CI TECNOLOGÍA ALIMENTARIA S.A.S ''CI TALSA'' es una sociedad comercial legalmente constituida con domicilio principal en la Carrera 50 GG No. 12 Sur 83, municipio de Itagüí, departamento de Antioquia, República de Colombia. En cumplimiento de la Ley 1581 de 2012, reglamentada por el Decreto 1377 de 2013, en materia de protección de datos personales, expide el siguiente reglamento para su adecuado manejo, en cumplimiento de las normas legales y la filosofía de nuestra empresa. Para mayor información visite el siguiente enlace: https://www.citalsa.com/pages/politica-para-el-tratamiento-de-datos-personales", 265);
                doc.setFont('', 'bold');
                doc.setFillColor(255, 255, 255); // Set the background color to light gray (RGB: 245, 245, 245)
                doc.rect(5, RetanguloPreguntas, 200, 80, 'F'); // Draw a rectangle with the background color
                doc.setTextColor(5, 0, 0); // Set the text color to black
                doc.text(politicas, 6, textoTablaPreguntas, 'left');
                
                if (RetanguloPreguntas >= 150) {
                    doc.addPage()
                    textoTablaPreguntas = 10;
                    RetanguloPreguntas = 5;
                }


                textoTablaPreguntas = textoTablaPreguntas + 20;
                RetanguloPreguntas = RetanguloPreguntas + 20;
                doc.setFont('', 'bold');
                doc.setFillColor(245, 245, 245); // Set the background color to light gray (RGB: 245, 245, 245)
                doc.rect(5, RetanguloPreguntas, 143, 9, 'FD'); // Draw a rectangle with the background color
                doc.setTextColor(5, 0, 0); // Set the text color to black
                doc.text('RESPONSABLE DE REALIZAR LA VISITA', 6, textoTablaPreguntas, 'left');

                //-----
                doc.setFont('', 'bold');
                doc.setFillColor(245, 245, 245);  // Set the background color to light gray (RGB: 245, 245, 245)
                doc.rect(143, RetanguloPreguntas, 143, 9, 'FD'); // Draw a rectangle with the background color
                doc.setTextColor(5, 0, 0); // Set the text color to black
                doc.text('RESPONSABLE DE RECIBIR LA VISITA', 144, textoTablaPreguntas, 'left');

                //-----------------------------------------------------
                if (RetanguloPreguntas >= 190) {
                    doc.addPage()
                    textoTablaPreguntas = 10;
                    RetanguloPreguntas = 5;
                }

                textoTablaPreguntas = textoTablaPreguntas + 9;
                RetanguloPreguntas = RetanguloPreguntas + 9;
                doc.setFont('', 'bold');
                doc.setFillColor(255, 255, 255); // Establece el color de fondo en gris claro (RGB: 245, 245, 245)
                doc.rect(5, RetanguloPreguntas, 143, 9, 'FD'); // Dibuja un rectángulo con el color de fondo
                doc.setTextColor(5, 0, 0); // Establece el color del texto en negro
                doc.text(responsableVisita, 6, textoTablaPreguntas, 'left');
                //-----
                doc.setFont('', 'bold');
                doc.setFillColor(255, 255, 255);  // Establece el color de fondo en gris claro (RGB: 245, 245, 245)
                doc.rect(143, RetanguloPreguntas, 143, 9, 'FD'); // Dibuja un rectángulo con el color de fondo
                doc.setTextColor(5, 0, 0); // Establece el color del texto en negro
                doc.text(responsableRecibir, 144, textoTablaPreguntas, 'left');

                textoTablaPreguntas = textoTablaPreguntas + 9;
                RetanguloPreguntas = RetanguloPreguntas + 9;
                doc.addImage(firma, 'JPEG', 0, RetanguloPreguntas, 143, 45);
                doc.setLineWidth(0.3); // Establece el grosor del borde en 1 (puedes ajustarlo según sea necesario)
                doc.setDrawColor(0); // Establece el color del borde en negro (RGB: 0, 0, 0)
                doc.rect(5, RetanguloPreguntas, 138, 45); // Dibuja un rectángulo alrededor de la primera imagen

                doc.addImage(firma2, 'JPEG', 143, RetanguloPreguntas, 143, 45);
                doc.setLineWidth(0.3); // Establece el grosor del borde en 1 (puedes ajustarlo según sea necesario)
                doc.setDrawColor(0); // Establece el color del borde en negro (RGB: 0, 0, 0)
                doc.rect(143, RetanguloPreguntas, 143, 45); // Dibuja un rectángulo alrededor de la segunda imagen

                //-----
                if (RetanguloPreguntas >= 190) {
                    doc.addPage()
                    textoTablaPreguntas = 10;
                    RetanguloPreguntas = 5;
                }
            } else if (element == 5) {
                if (RetanguloPreguntas >= 190) {
                    doc.addPage()
                    textoTablaPreguntas = 10;
                    RetanguloPreguntas = 5;
                }
                textoTablaPreguntas = textoTablaPreguntas + 55;
                RetanguloPreguntas = RetanguloPreguntas + 55;

                if (RetanguloPreguntas >= 190) {
                    doc.addPage()
                    textoTablaPreguntas = 10;
                    RetanguloPreguntas = 5;
                }
                // Draw rectangles with background colors and text
                doc.setFont('', 'bold');
                doc.setFillColor(255, 255, 255); // Set the background color to light gray (RGB: 245, 245, 245)
                doc.rect(50, RetanguloPreguntas, 25, 16, 'FD'); // Draw a rectangle with the background color
                doc.setTextColor(5, 0, 0); // Set the text color to black
                doc.text('Posición', 51, textoTablaPreguntas + 4.5, 'left');

                doc.setFont('', 'bold');
                doc.setFillColor(255, 255, 255); // Set the background color to light gray (RGB: 245, 245, 245)
                doc.rect(70, RetanguloPreguntas, 60, 8, 'FD'); // Draw a rectangle with the background color
                doc.setTextColor(5, 0, 0); // Set the text color to black
                doc.text('Preparado por:', 76, textoTablaPreguntas, 'left');

                doc.setFont('', 'bold');
                doc.setFillColor(255, 255, 255); // Set the background color to light gray (RGB: 245, 245, 245)
                doc.rect(125, RetanguloPreguntas, 50, 8, 'FD'); // Draw a rectangle with the background color
                doc.setTextColor(5, 0, 0); // Set the text color to black
                doc.text('Revisado por:', 126, textoTablaPreguntas, 'left');

                doc.setFont('', 'bold');
                doc.setFillColor(255, 255, 255); // Set the background color to light gray (RGB: 245, 245, 245)
                doc.rect(170, RetanguloPreguntas, 60, 8, 'FD'); // Draw a rectangle with the background color
                doc.setTextColor(5, 0, 0); // Set the text color to black
                doc.text('Aprobado pór:', 176, textoTablaPreguntas, 'left');

                textoTablaPreguntas = textoTablaPreguntas + 8;
                RetanguloPreguntas = RetanguloPreguntas + 8;

                // Draw rectangles with text
                doc.setFont('', '');
                doc.setFillColor(255, 255, 255); // Set the background color to light gray (RGB: 245, 245, 245)
                doc.rect(70, RetanguloPreguntas, 60, 8, 'FD'); // Draw a rectangle with the background color
                doc.setTextColor(5, 0, 0); // Set the text color to black
                doc.text('Líder de negociación en compras', 76, textoTablaPreguntas, 'left');

                doc.setFont('', '');
                doc.setFillColor(255, 255, 255); // Set the background color to light gray (RGB: 245, 245, 245)
                doc.rect(125, RetanguloPreguntas, 60, 8, 'FD'); // Draw a rectangle with the background color
                doc.setTextColor(5, 0, 0); // Set the text color to black
                doc.text('Analista de SGI', 126, textoTablaPreguntas, 'left');

                doc.setFont('', '');
                doc.setFillColor(255, 255, 255); // Set the background color to light gray (RGB: 245, 245, 245)
                doc.rect(170, RetanguloPreguntas, 60, 8, 'FD'); // Draw a rectangle with the background color
                doc.setTextColor(5, 0, 0); // Set the text color to black
                doc.text('Director de Cadena de suministro', 176, textoTablaPreguntas, 'left');

                textoTablaPreguntas = textoTablaPreguntas + 8;
                RetanguloPreguntas = RetanguloPreguntas + 8;

                // Draw a rectangle with the text
                doc.setFont('', 'bold');
                doc.setFillColor(255, 255, 255); // Set the background color to light gray (RGB: 245, 245, 245)
                doc.rect(50, RetanguloPreguntas, 25, 8, 'FD'); // Draw a rectangle with the background color
                doc.setTextColor(5, 0, 0); // Set the text color to black
                doc.text('Fecha', 51, textoTablaPreguntas, 'left');

                doc.setFont('', '');
                doc.setFillColor(255, 255, 255); // Establece el color de fondo en gris claro (RGB: 245, 245, 245)
                doc.rect(70, RetanguloPreguntas, 60, 8, 'FD'); // Dibuja un rectángulo con el color de fondo
                doc.setTextColor(5, 0, 0); // Establece el color del texto en negro
                doc.text('01-06-2023', 76, textoTablaPreguntas, 'left');
                doc.setFont('', '');
                doc.setFillColor(255, 255, 255); // Establece el color de fondo en gris claro (RGB: 245, 245, 245)
                doc.rect(125, RetanguloPreguntas, 60, 8, 'FD'); // Dibuja un rectángulo con el color de fondo
                doc.setTextColor(5, 0, 0); // Establece el color del texto en negro
                doc.text('01-06-2023', 126, textoTablaPreguntas, 'left');
                doc.setFont('', '');
                doc.setFillColor(255, 255, 255); // Establece el color de fondo en gris claro (RGB: 245, 245, 245)
                doc.rect(170, RetanguloPreguntas, 60, 8, 'FD'); // Dibuja un rectángulo con el color de fondo
                doc.setTextColor(5, 0, 0); // Establece el color del texto en negro
                doc.text('05-06-2023', 176, textoTablaPreguntas, 'left');


                doc.setFontSize(10)
                if (RetanguloPreguntas >= 190) {
                    doc.addPage()
                    textoTablaPreguntas = 5;
                    RetanguloPreguntas = 0;
                }
            }
            doc.setFontSize(10)
            if (RetanguloPreguntas >= 190) {
                doc.addPage()
                textoTablaPreguntas = 5;
                RetanguloPreguntas = 0;
            }
        })



        /*doc.setFillColor(255, 0, 0);
        doc.rect(45, 200, 200, 210, 'FD'); // Dibuja un rectángulo con el color de fondo
    */
        const pdfData = doc.output('arraybuffer');

        // Crea un objeto Blob a partir de los datos del PDF
        const pdfBlob = new Blob([pdfData], { type: 'application/pdf' });
        const pdfUrl = URL.createObjectURL(pdfBlob);
        // Crea un objeto FileReader
        const reader = new FileReader();
        // Lee el contenido del Blob como una URL de datos
        reader.readAsDataURL(pdfBlob);
        // Escucha el evento "load" cuando la lectura se haya completado
        reader.onload = function () {
            // Obtiene la cadena base64 del resultado de la lectura
            const base64String = reader.result.split(',')[1];

            let correos = "";
            contactos.forEach(element => {
                correos = correos + "," + element.email
            });

          // console.log("cpenuela@citalsa.com" + correos)
            //window.open(pdfUrl, '_blank');
            // Usa la cadena base64 como sea necesario (por ejemplo, enviarla al servidor, mostrarla en un elemento HTML, etc.)
           EnviarCorreopdf(base64String, "cpenuela@citalsa.com"+","+localStorage.Email, razonSocial, "EVALUACIÓN DE VISITA DE PROVEEDORES").then((result2) => {
            })
            resolve(base64String)
        };
        /*  window.open(pdfUrl, '_blank');*/
    })

}

