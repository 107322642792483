import * as React from 'react';
import { styled } from '@mui/material/styles';
import Cardv from '@mui/material/Card';
import { Card, Text } from "@nextui-org/react";
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { abrirPdf } from '../FunctionLogin.js'
import pdf from '../../recursos/pdfrojo.jpg'
import Button from '@mui/material/Button';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FolderIcon from '@mui/icons-material/Folder';
import DeleteIcon from '@mui/icons-material/Delete';
import pdfjs from 'pdfjs-dist';


const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function RecipeReviewCard({ Description, PublishDate, Ext, FileId, Size, tipovista }) {
  const [expanded, setExpanded] = React.useState(false);
  const [secondary, setSecondary] = React.useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };


  return (
    <div>
      {tipovista ? (
        <ListItem style={{ cursor: "pointer" }}
          onClick={() => {
            (FileId).then((result) => {
              const b64 = result.Code;
              const nombreArchivo = Description; // Si no se proporciona nombre, usar 'archivo'
            
              // Decodificar el contenido base64
              const byteArray = atob(b64);
              // Crear un Blob a partir de los datos decodificados
              const blob = new Blob([new Uint8Array(byteArray.length).map((_, i) => byteArray.charCodeAt(i))], {
                type: Ext === '.pdf' ? 'application/pdf' : 'application/octet-stream', // Tipo MIME según la extensión
              });
              // Crear una URL del Blob
              const url = URL.createObjectURL(blob);
            
              // Verificar si es un dispositivo móvil
              const isMobile = window.innerWidth < 768;
            
              if (Ext === '.pdf') {
                // Comportamiento para archivos PDF
                if (isMobile) {
                  // En dispositivos móviles, descargar el PDF
                  const link = document.createElement('a');
                  link.href = url;
                  link.download = nombreArchivo+Ext || 'archivo.pdf'; // Nombre del archivo o 'archivo.pdf'
                  link.click();
                } else {
                  // En otros dispositivos, abrir el PDF en una nueva ventana
                  const newWindow = window.open(url, '_blank');
                  if (newWindow) {
                    newWindow.document.write('<iframe width="100%" height="100%" src="' + url + '"></iframe>');
                  } else {
                    alert('Tu navegador bloqueó la apertura de una nueva ventana. Asegúrate de habilitar las ventanas emergentes.');
                  }
                }
              } else {
                // Comportamiento para archivos que no son PDF (descargar automáticamente)
                const link = document.createElement('a');
                link.href = url;
                link.download = nombreArchivo+Ext; // Usa el nombre del archivo
                link.click();
              }
            });
          }}
        >
          <ListItemAvatar>
            <Avatar>
              <FolderIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={Description + " - " + "Fecha:" + PublishDate + "- Tipo de documeto: " + Ext + " Tamaño del documento: " + Size}
            secondary={secondary ? Description : null}
          />
        </ListItem>

      ) : (
        <Card isPressable
          isHoverable
          variant="bordered" sx={{ maxWidth: 300 }} >
          <CardMedia
            onClick={() => {
              abrirPdf(FileId).then((result) => {
                const b64 = result.Code;
                const nombreArchivo = Description; // Si no se proporciona nombre, usar 'archivo'
              
                // Decodificar el contenido base64
                const byteArray = atob(b64);
                // Crear un Blob a partir de los datos decodificados
                const blob = new Blob([new Uint8Array(byteArray.length).map((_, i) => byteArray.charCodeAt(i))], {
                  type: Ext === '.pdf' ? 'application/pdf' : 'application/octet-stream', // Tipo MIME según la extensión
                });
                // Crear una URL del Blob
                const url = URL.createObjectURL(blob);
              
                // Verificar si es un dispositivo móvil
                const isMobile = window.innerWidth < 768;
              
                if (Ext === '.pdf') {
                  // Comportamiento para archivos PDF
                  if (isMobile) {
                    // En dispositivos móviles, descargar el PDF
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = nombreArchivo+Ext || 'archivo.pdf'; // Nombre del archivo o 'archivo.pdf'
                    link.click();
                  } else {
                    // En otros dispositivos, abrir el PDF en una nueva ventana
                    const newWindow = window.open(url, '_blank');
                    if (newWindow) {
                      newWindow.document.write('<iframe width="100%" height="100%" src="' + url + '"></iframe>');
                    } else {
                      alert('Tu navegador bloqueó la apertura de una nueva ventana. Asegúrate de habilitar las ventanas emergentes.');
                    }
                  }
                } else {
                  // Comportamiento para archivos que no son PDF (descargar automáticamente)
                  const link = document.createElement('a');
                  link.href = url;
                  link.download = nombreArchivo+Ext; // Usa el nombre del archivo
                  link.click();
                }
              });
            }}
            style={{ objectFit: "contain", cursor: "pointer" }}
            component="img"
            height="150"
            image={pdf}
            alt="archivo"
          />

          <CardActions disableSpacing>
          </CardActions>
          <span style={{ display: "-webkit-box", WebkitLineClamp: "2", WebkitBoxOrient: "vertical", overflow: "hidden", textOverflow: "ellipsis" }}
            title={Description}>{Description}</span>
          <span title={PublishDate}>{PublishDate}</span>
          <span title>{Size > 1000000 ? "Elemento sin visualización" : ""}</span>
        </Card>
      )}
    </div>
  );
}
